<template>

  <BModal ref="bmodal">

    <div class="header">{{header}}</div>
    <div class="space"></div>     
    <div class="description">{{description}}</div>
    <div class="space"></div>   

    <Mbutton
      :rotulo="fstButtonTxt"
      tipo="button"
      :confirmacao="false"
      @botaoAtivado="makeAction()"
    />
    <div class="space"></div>
      
  </BModal>

</template>

<script>

export default {
  props: {
    header: String,
    description: String,
    fstButtonTxt: String,
    sndButtonTxt: String,
    action: Function
  },
  data() {
    return {
      showModal: false,
      isCancel:false
    };
  },
  methods: {
    makeAction(){
      this.$emit('action');
    },
    enable(){
      this.$refs.bmodal.enable();
    },
    disable(){
      this.$refs.bmodal.disable();
    }
  }
};
</script>

<style scoped>

.header{
  margin-top: 8px;
  font-style: normal;
  font-weight: bold;
  font-size: 24px;
  line-height: 32px;
  color: var(--confcons-modal-main-title-color);
}
.description{
  font-size: 16px;
  line-height: 24px;
  padding: none;
  margin-bottom: 4px;
}
.space{
  padding: none;
  margin: none;
  height: 14px;
}

.descriptionBold{
  font-weight: bold !important;
}
</style>