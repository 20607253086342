import { createRouter, createWebHistory ,RouteRecordRaw } from 'vue-router';

// Rota buscada em tempo de código
// Isso gera um pedaço de código separado (about.[hash].js) para esta rota
// Que usa lazy-load quando a rota é visitada.
const routes:RouteRecordRaw[] = [];

routes.push({
  path: '/ifgo',
  name: 'Redirect IF',
  component: () => import('../views/Ifgo.vue'),
  meta: { redirect: true }
});

if(process.env.VUE_APP_ENABLE_DEV_VIEWS != '0'){
  routes.push({
    path: '/home',
    name: 'Home',
    component: () => import('../components/Home/Home.vue')
  });
  routes.push({
    path: '/map',
    name: 'Map',
    component: () => import('../views/Map.vue')
  });
  routes.push({
    path: '/',
    name: 'TppSimulator',
    component: () => import('../views/TppSimulator.vue')
  });
}

if(process.env.VUE_APP_ENABLE_CLIENT_CONFIRM_VIEWS != '0' || process.env.VUE_APP_ENABLE_CLIENT_CONSULT_VIEWS != '0'){
  routes.push({
    path: '/auth',
    name: 'auth',
    component: () => import('../views/BlankPage.vue'),
    meta: { authenticated: true }
  });
  routes.push({
    path: '/contactUs',
    name: 'contactUs',
    component: () => import('../views/ContactUs.vue'),
    meta: { authenticated: true, data: true }
  });
}

if(process.env.VUE_APP_ENABLE_CLIENT_CONFIRM_VIEWS != '0'){
  routes.push({
    path: '/confirmConsentPayment',
    name: 'ConfirmConsentPayment',
    component: () => import('../views/ConfirmConsentPayment.vue'),
    meta: { authenticated: true, data: true }
  });
  routes.push({
    path: '/confirmConsentPersonalData',
    name: 'ConfirmConsentPersonalData',
    component: () => import('../views/ConfirmConsentPersonalData.vue'),
    meta: { authenticated: true, data: true, stage: 2 }
  });
  routes.push({
    path: '/selectAccount/',
    name: 'SelectAccount',
    component: () => import('../views/SelectAccount.vue'),
    meta: { authenticated: true, data: true }
  });
  routes.push({
    path: '/redirect',
    name: 'Redirect',
    component: () => import('../views/Redirect.vue'),
    meta: { authenticated: true, data: true }
  });
  
}

if(process.env.VUE_APP_ENABLE_CLIENT_CONSULT_VIEWS != '0'){
  routes.push({
    path: '/consultConsents',
    name: 'ConsultConsents',
    component: () => import('../views/ConsultConsent.vue'),
    meta: { authenticated: true, data: true }
  });
  routes.push({
    path: '/myConsents',
    name: 'MyConsents',
    component: () => import('../views/MyConsents.vue'),
    meta: { authenticated: true, data: true }
  });
  routes.push({
    path: '/detailConsent/:detailView',
    name: 'DetailConsent',
    component: () => import('../views/DetailConsent.vue'),
    meta: { authenticated: true, data: true }
  });
  routes.push({
    path: '/faq',
    name: 'faq',
    component: () => import('../views/FAQ.vue'),
    meta: { authenticated: true, data: true }
  });
  routes.push({
    path: '/consultConsent',
    name: 'consultConsent',
    component: () => import('../views/ConsultConsent.vue'),
    meta: { authenticated: true, data: true }
  });
  routes.push({
    path: '/error',
    name: 'Error',
    component: () => import('../views/ErrorPage.vue')
  });
  routes.push({
    path: '/errorAuth',
    name: 'Error Authenticated',
    component: () => import('../views/ErrorPage.vue'),
    meta: { authenticated: true, data: true }
  });
  routes.push({
    path: '/myConsentsPersonalData',
    name: 'MyConsentsPersonalData',
    component: () => import('../views/MyConsentsPersonalData.vue'),
    meta: { authenticated: true, data: true, stage: 2 }
  });
  routes.push({
    path: '/detailPersonalData/origin',
    name: 'DetailPersonalDataOrigin',
    component: () => import('../views/DetailPersonalDataOrigin.vue'),
    meta: { authenticated: true, data: true, stage: 2}
  });
  routes.push({
    path: '/detailPersonalData/:detailView',
    name: 'DetailPersonalData',
    component: () => import('../views/DetailPersonalData.vue'),
    meta: { authenticated: true, data: true, stage: 2 }
  });
  routes.push({
    path: '/myConsentsAuthorizations',
    name: 'MyConsentsAuthorizations',
    component: () => import('../views/MyConsentsAuthorizations.vue'),
    meta: { authenticated: true, data: true, stage: 2 }
  });
}

if(process.env.VUE_APP_ENABLE_MANAGER_VIEWS != '0'){
  routes.push({
    path: '/manager/login',
    name: 'ManagerLogin',
    component: () => import('../views/Manager/ManagerLogin.vue')
  });
  routes.push({
    path: '/login',
    name: 'LoginToken',
    component: () => import('../views/Manager/LoginToken.vue'),
  });
  routes.push({
    path: '/manager/home',
    name: 'ManagerHome',
    component: () => import('../views/Manager/ManagerHome.vue'),
  });
  routes.push({
    path: '/manager/consultConsent',
    name: 'ManagerConsultConsent',
    component: () => import('../views/Manager/ManagerConsultConsent.vue'),
  });
  routes.push({
    path: '/manager/consultPersonalData',
    name: 'ManagerConsultPersonalData',
    component: () => import('../views/Manager/ManagerConsultShared.vue'),
  });
  routes.push({
    path: '/manager/consultPersonalData/resume',
    name: 'ManagerDetailConsentShared',
    component: () => import('../views/Manager/ManagerDetailConsentShared.vue'),
  });
  routes.push({
    path: '/manager/detailConsent',
    name: 'ManagerDetailConsent',
    component: () => import('../views/Manager/ManagerDetailConsent.vue'),
  });
  routes.push({
    path: '/manager/historyConsent',
    name: 'ManagerHistoryConsent',
    component: () => import('../views/Manager/ManagerHistoryConsent.vue'),
  });
  routes.push({
    path: '/manager/consultPersonalData/history',
    name: 'ManangerHistoryShare',
    component: () => import('../views/Manager/ManangerHistoryShare.vue'),
  });
}

const router = createRouter({
  history: createWebHistory(),
  routes
});

function isAuthenticatedAndStage2(to) {
  return to.meta.authenticated && to.meta.stage === 2;
}

function isAuthenticatedWithData(to) {
  return to.meta.authenticated && (!to.meta.data || (document.cookie && sessionStorage.getItem('dados')));
}

function clearCookies() {
  const ck = document.cookie;
  if (ck) {
    const allCookies = document.cookie.split(';');
    for (const cookie of allCookies) {
      document.cookie = cookie + '=;expires=' + new Date(0).toUTCString();
    }
  }
}

function checkAndRefreshPage(to, from, next) {
  if (from.fullPath === '/detailConsent/resume_history' && to.fullPath === '/detailConsent/resume') {
    window.location.reload();
    next('/detailConsent/resume');
  } else {
    next();
  }
}

router.beforeEach((to, from, next) => {
  checkAndRefreshPage(to, from, () => {
    if (isAuthenticatedAndStage2(to)) {
      const theme = sessionStorage.getItem('theme');
      if (theme === 'ccb') {
        next('/ifgo');
      } else {
        next();
      }
    } else if (isAuthenticatedWithData(to)) {
      next();
    } else if (to.matched.length === 0) {
      next('/ifgo');
    } else {
      clearCookies();
      next();
    }
  });
});

export default router;
