<template>

  <BManagerModal ref="bmodalc" customClass="filterModalBox" customClassDisable="filterModalDisable">

    <div class="fWrapper">

      
      <div class="divHeader">
        <div class="divHeaderTitle">
          <span class="header">Escolha um período</span>
        </div>
        <div class="divHeaderError">
          <span v-if="!isValidIntervalDates && tryedToFilter" class="headerError">Datas inválidas</span>
        </div>
        <span class="closeBtn" @click="disable()"><i class="pi pi-times"></i></span>
      </div>

      <Calendar 
        ref="fCalendar" 
        class="filterPCalendarManager"
        :disabled="usingInputMasks" 
        v-model="dateModel" :inline="true" 
        :showWeek="false" 
        dateFormat="dd/mm/yy" 
        selectionMode="range"
      />

      <div>
        <Button class="modalCalendarClear" @click="clearFilter">
          Limpar
        </Button>

        <Button :disabled="!isValidIntervalDates && tryedToFilter" class="modalCalendarFilter" @click="makeAction()">
          Filtrar
        </Button>
      </div>

    </div>
      
  </BManagerModal>

</template>

<script>

import moment from 'moment';

export default {
  data() {
    return {
      showModal: false,
      dateModel: null,
      strDateModelStart: null,
      strDateModelEnd: null,
      dateModelStart: null,
      dateModelEnd: null,

      usingInputMasks: false,
      usingCalendarInput: false,
      isStartDate: true,

      isValidDateDigits: true,
      isValidIntervalDates: false,
      tryedToFilter: false,
    };
  },
  methods: {
    enable(){
      this.$refs.bmodalc.enable();
    },
    disable(){
      this.$refs.bmodalc.disable();
    },

    clearFilter () {
      this.$refs.fCalendar.updateModel(null);
    },

    makeAction(){
      this.tryedToFilter = true;
      this.updateIsValidIntervalDates();

      if (this.isValidIntervalDates){
        this.$emit('action');
        this.disable();
      }

    },
    isValidDateStr(date){
      if (date == null || date == '' || date.includes('d') || date.includes('m') || date.includes('a'))
        return false;
      return true;
    },
    verifyValidDateDigits(){
      if(this.isValidDateStr(this.strDateModelStart) && this.isValidDateStr(this.strDateModelEnd)){
        this.isValidDateDigits = moment(this.strDateModelStart, 'DD/MM/YYYY', true).isValid() && moment(this.strDateModelEnd, 'DD/MM/YYYY', true).isValid();
      }
      else if(this.isValidDateStr(this.strDateModelStart)){
        this.isValidDateDigits = moment(this.strDateModelStart, 'DD/MM/YYYY', true).isValid();
      }
      else if(this.isValidDateStr(this.strDateModelEnd)){
        this.isValidDateDigits = moment(this.strDateModelEnd, 'DD/MM/YYYY', true).isValid();
      }
      else{
        this.isValidDateDigits = true;
      }
    },
    updateIsValidIntervalDates(){
      this.verifyValidDateDigits();
      this.isValidIntervalDates = this.isValidDateDigits && this.dateModelStart != null && this.dateModelStart != '' && 
        this.dateModelEnd != null && this.dateModelEnd != '' && this.dateModelStart.getTime() <= this.dateModelEnd.getTime();
    },
    getFormatedDateddmmyyyy(date){
      return ('0' + date.getDate()).slice(-2) + '/' + ('0' + (date.getMonth()+1)).slice(-2) + '/' + ('000' + date.getFullYear()).slice(-4);
    },

    onEsc(e) {
      if (!e) e = window.event;
      const keyCode = e.keyCode || e.which;
      if (keyCode == '27') {
        this.disable();
      }
    }

  },
  watch: {
    dateModel: function (val) {

      this.tryedToFilter = false;

      if( Array.isArray(val) ){
        if (val[1] == null || val[1] == ''){
          const dateTmp = this.getFormatedDateddmmyyyy(val[0]);
          this.strDateModelEnd = 'dd/mm/aaaa';
          this.strDateModelStart = dateTmp;

          if (this.isValidDateStr(this.strDateModelStart)){
            const tmp = this.strDateModelStart.split('/');
            this.dateModelStart = new Date(`${tmp[1]}/${tmp[0]}/${tmp[2]}`);
          }

        }
        else{
          const dateTmp = this.getFormatedDateddmmyyyy(val[1]);
          this.strDateModelEnd = dateTmp;

          if (this.isValidDateStr(this.strDateModelEnd)){
            const tmp = this.strDateModelEnd.split('/');
            this.dateModelEnd = new Date(`${tmp[1]}/${tmp[0]}/${tmp[2]}`);
          }

        }
      }
      
        this.updateIsValidIntervalDates();
    } 
  },
  created(){
    // document.addEventListener('keyup', this.onEsc);

    this.$primevue.config.locale.dayNamesMin = [ 'Dom', 'Seg', 'Ter', 'Qua', 'Qui', 'Sex', 'Sáb' ];
    this.$primevue.config.locale.monthNames = [ 'Janeiro', 'Fevereiro', 'Março', 'Abril', 'Maio', 'Junho',
      'Julho', 'Agosto', 'Setembro', 'Outubro', 'Novembro', 'Dezembro' ];
  },

  beforeUnmount() {
    // document.removeEventListener('keyup', this.onEsc);
  },

  mounted(){
    document.getElementById(document.querySelector('div[id^=\'pv_id_\'][id$=\'_panel\']').id)?.removeAttribute('style');
  }
};
</script>

<style scoped>

.divCloseBtn{
  text-align: left;
  margin-bottom: 5px;
}
.divHeader{
  display: flex;
  justify-content: space-between;
  margin: 0px auto 3px auto;
  padding: 0 12px;
}
.divHeaderTitle, .divHeaderError{
  display: inline-block;
}
.divHeaderTitle{
  float: left;
}
.divHeaderError{
  float: right;
}
.closeBtn, .header{
  font-style: normal;
  font-size: 24px;
  line-height: 32px;
}

.header{
  color: #414141;
}
.headerError{
  font-size: 12px;
  font-weight: normal;
  color: #F20C0C;
}
.closeBtn{
  font-weight: normal;
  color: #F20C0C;
  cursor: pointer;
}
.pi-times:hover, .pi-times:focus{
  font-weight: bold;
}

.modalCalendarClear{
  display: inline-block;
  width: 40%;
  font-weight: bold;
  height: 60px;
  margin-right: 40px;
  background-color: #FFFFFF !important;
  border: 2px solid #808080 !important;
  color: #808080 !important;
  box-shadow: none !important;
}
.modalCalendarClear:hover{
  background-color: #fafafa !important;
  border: 2px solid #808080 !important;
}
.modalCalendarFilter{
  display: inline-block;
  width: 40%;
  font-weight: bold;
  height: 60px;
  background-color: #ED1846 !important;
  border: none !important;
  color: #FFFFFF !important;
  box-shadow: none !important;
}
.modalCalendarFilter:hover{
  background-color: #ff2f5c !important;
  border: none !important;
  color: #FFFFFF !important;
}

</style>

<style>
/* not scoped for calendar */

.filterPCalendarManager .datepicker{
  border: none !important;
}
.filterPCalendarManager .datepicker, .filterPCalendarManager .datepicker-header{
  background-color: #ffffff !important;
}
.filterPCalendarManager .datepicker-month, .filterPCalendarManager .datepicker-year{
  font-weight: bold;
  color: #0D0C0C;
}
.filterPCalendarManager .datepicker-prev-icon, .filterPCalendarManager .datepicker-next-icon{
  color: #0D0C0C;
}
.filterPCalendarManager.disableNextPreviousMonth .datepicker-prev, .filterPCalendarManager.disableNextPreviousMonth .datepicker-next{
  display: none;
}
.filterPCalendarManager .datepicker-calendar thead span{
  color: rgb(13, 12, 12);
}
.filterPCalendarManager .datepicker-calendar tbody span, .filterPCalendarManager .datepicker-calendar td.datepicker-today > span{
  color: rgb(65, 65, 65) !important;
}
.filterPCalendarManager .datepicker-today > span{
  background-color: rgba(242, 12, 12, 0.4) !important;
}
.filterPCalendarManager .datepicker-today > span.highlight,
.filterPCalendarManager .datepicker-today > span:focus,
.filterPCalendarManager .datepicker-today > span:hover,
.filterPCalendarManager .datepicker table td > span.highlight,
.filterPCalendarManager .datepicker table td > span:focus,
.filterPCalendarManager .datepicker table td > span:hover,
.filterPCalendarManager .datepicker .datepicker-header .datepicker-prev:focus,
.filterPCalendarManager .datepicker .datepicker-header .datepicker-prev:hover,
.filterPCalendarManager .datepicker .datepicker-header .datepicker-next:focus,
.filterPCalendarManager .datepicker .datepicker-header .datepicker-next:hover{
  background-color: rgba(242, 12, 12, 0.2) !important;
}
.filterPCalendarManager .datepicker-today > span:focus,
.filterPCalendarManager .datepicker table td > span:focus,
.filterPCalendarManager .datepicker .datepicker-header .datepicker-prev:focus,
.filterPCalendarManager .datepicker .datepicker-header .datepicker-next:focus{
  outline: 0 none !important;
  outline-offset: 0 !important;
  box-shadow: 0 0 0 0.2rem rgba(242, 12, 12, 0.4) !important;
}

.p-datepicker {
  border: none !important;
}

</style>