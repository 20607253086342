<template>
  
  <NavBar
    v-if="appCreated && $router.currentRoute.path !== 'ifgo'"
    ref="mainNavbar"
    ifName="BANCO"
    :bntGoBackLabel="internalizationApp.topBarReturnLabel"
  />


  <router-view
    v-if="appCreated"
  />

  <LoadingModal
    v-if="appCreated"
    ref="loadingModal"
  />

  <ExceptionModal
    v-if="appCreated"
    ref="exceptionWarnigFetchingData"
    tipo='error'
    :messageErr="internalizationApp.fluxConsultConsErrFetchTimeOutLabel"
    :detailErr="internalizationApp.fluxConsultConsErrFetchTimeOutMsg"
  />

  <ExceptionModal
    v-if="appCreated"
    ref="exceptionWarnigFetchingDataPay"
    tipo='error'
    :messageErr="internalizationApp.fluxConfConsErrFetchTimeOutLabel"
    :detailErr="internalizationApp.fluxConfConsErrFetchTimeOutMsg"
  />

</template>

<script>
import { getTheme, getWithExp, setWithExp } from '@/util/util.js';
import { defineComponent } from 'vue';

import defaultInter from '@/internalization/default.json';
import amzInter from '@/internalization/amz.json';
import bcdInter from '@/internalization/bcd.json';
import bmxInter from '@/internalization/bmx.json';
import bs2Inter from '@/internalization/bs2.json';
import ccbInter from '@/internalization/ccb.json';
import cdsInter from '@/internalization/cds.json';
import letInter from '@/internalization/let.json';
import oriInter from '@/internalization/ori.json';
import voiInter from '@/internalization/voi.json';
import zemInter from '@/internalization/zem.json';
import csyInter from '@/internalization/csy.json';


export default defineComponent({
  data() {
    return {
      publicPath: process.env.BASE_URL,
      appCreated: false,

      internalization: defaultInter
    };
  },
  methods:{
    getInternalizationLocal(theme){

      switch(theme){
        case('amz'): return amzInter;
        case('bcd'): return bcdInter;
        case('bmx'): return bmxInter;
        case('bs2'): return bs2Inter;
        case('ccb'): return ccbInter;
        case('cds'): return cdsInter;
        case('let'): return letInter;
        case('ori'): return oriInter;
        case('voi'): return voiInter;
        case('zem'): return zemInter;
        case('csy'): return csyInter;
        default: return defaultInter;
      }
      
    },
    async getInternalizationBD(){

      const requestOptions = {
        method: 'GET',
        redirect: 'follow'
      };

      try{
        const response = await fetch(process.env.VUE_APP_CONSENTS+'/consent/tomdevoz', requestOptions);
        if (!response.ok) {
          return 'fetchErr';
        }
        const tom = await response.json();

        return tom;
      }
      catch (error){
        return 'fetchErr';
      }
    }
  },
  async created() {

    this.appCreated = false;
    window.onbeforeunload  = function(){
      window.scrollTo(0, 0);
    };

    /* theme */
    const theme = getTheme();
    const baseUrl = window.location.origin;
    const cssFilePath = `${baseUrl}/ifThemes/${theme}.css`;
    const stylesheet = document.createElement('link');
    stylesheet.setAttribute('rel', 'stylesheet');
    stylesheet.setAttribute('type', 'text/css');
    stylesheet.setAttribute('href', cssFilePath);
    document.body.append(stylesheet);
    /* */
    
    /* internalization */
    let internalizationTmp;
    if(process.env.VUE_APP_IF_NAME.toLowerCase().trim() == 'qa'){

      internalizationTmp = this.getInternalizationLocal(theme);
      setWithExp('internalization', JSON.stringify(internalizationTmp));
    }
    else{
      internalizationTmp = getWithExp('internalization');

      if (internalizationTmp != null){
        internalizationTmp = JSON.parse(internalizationTmp);
      }

      if (internalizationTmp == null || 
          internalizationTmp.ifCode == null || 
          internalizationTmp.ifCode.toLowerCase().trim() != process.env.VUE_APP_IF_NAME.toLowerCase().trim()){
        
        internalizationTmp = this.getInternalizationLocal(theme);

        // if any error occurs, local internalization will be used, if fi`s local internalization is not found, then local default will be used
        if (internalizationTmp == 'fetchErr'){
          internalizationTmp = this.getInternalizationLocal(process.env.VUE_APP_IF_NAME.toLowerCase().trim());
        }

        setWithExp('internalization', JSON.stringify(internalizationTmp));
      }

    }

    this.internalizationApp = internalizationTmp;
    
    this.appCreated = true;
    /* */
  }
});
</script>


<style>

/* Try to place and use only this global styles in app*/
.p-timeline-event-opposite {
  display: none;
}

body {
  margin:0;
  font-family: var(--font-family);
}

.component{
  font-family: var(--font-family) !important;
}

.p-component{
  font-family: var(--font-family) !important;
}

.radioP .radiobutton-box{
  border: 2px solid var(--radio-border-color) !important;
  background: none !important;
}
.radioP .focus{
  box-shadow: 0px 0px 0.3rem var(--radio-fill-color) !important;
}
.radioP .radiobutton-icon{
  background-color: var(--radio-fill-color) !important;
}

.checkP .checkbox-box{
  border-radius: 4px;
  border: 1px solid var(--checkbox-border-color) !important;
  background: none !important;
}
.checkP .highlight{
  background: var(--checkbox-background-color) !important; 
}
.checkP .highlight .checkbox-icon{
  color: var(--checkbox-fill-color) !important;
  font-weight: 900 !important;
}
.checkP .focus{
  box-shadow: 0px 0px 0.3rem var(--checkbox-fill-color) !important;
}

.dropdownP{
  background: none !important;
  border: 2px solid var(--dropdown-border-color) !important;
}
.dropdownP.focus{
  box-shadow: 0px 0px 0.3rem var(--dropdown-focus-shadow-color) !important;
}
.dropdownP .dropdown-label, .dropdownP .dropdown-item{
  color: var(--dropdown-text-notselected-color) !important;
}
.dropdownP .pi{
  color: var(--dropdown-icon-color) !important;
}
.dropdownP .highlight{
  color: var(--dropdown-text-selected-color) !important;
  background-color: var(--dropdown-background-selected-color) !important;
}

.scrollbarP::-webkit-scrollbar, .dropdownP .dropdown-items-wrapper::-webkit-scrollbar {
  width: 10px;
}
.scrollbarP::-webkit-scrollbar-track, .dropdownP .dropdown-items-wrapper::-webkit-scrollbar-track {
  background: var(--page-background-color);
  border-radius: 8px;
}
.scrollbarP::-webkit-scrollbar-thumb, .dropdownP .dropdown-items-wrapper::-webkit-scrollbar-thumb {
  background-clip: padding-box;
  border-radius: 8px;
  background-color: var(--dropdown-icon-color);
}

/* css needed to insert html from value in faq with styles */
.faqHtml a, .faqHtml a:visited{
  text-decoration: none !important;
  color: var(--faq-unselected-title-color) !important;
  font-weight: bold !important;
}
.faqHtml a:hover{
  text-decoration: none !important;
  color: var(--faq-selected-title-color) !important;
  font-weight: bold !important;
}
.paginator .paginator-page-input .inputtext{
  height: 2.357rem !important; 
}

</style>
