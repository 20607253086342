
import { defineComponent } from 'vue';
import {GetConsentCssClass, GetConsentStatus} from '@/util/util.js';
import moment from 'moment';


export default defineComponent({
  name: 'ConsentStatusCardHistory',
  props: {
    id: Number,
    detailView: String,
    cardStatus: String,
    cardStatusTitle: String,
    cardStatusDescription: String,
    cardHistoryAwaitingDate: String,
    cardInfoTitle: String,
    consentID: String,
    isSweeping: Boolean,
    history: [Object]
  },
  created(){
    // console.log(this.isSweeping);
  },
  methods: {
    test(message: string) : void {
      console.log(message);
    },
    getCssClassBox(status: string) : string {
      return 'grid box box-' + status;
    },
    getCssColor(status: string) : string {
      return 'marker-' + GetConsentCssClass(status);
    },
    getStatus(status: string) {
      return GetConsentStatus(status);
    },
    getCssClass(status: string) : string {
      return 'col-12 card-header card-header-' + status;
    },
    getCssClassToggler(status: string) : string {
      return 'm-auto toggler toggler-' + status;
    },
    getCssClassDescriptionContainer(status: string) : string {
      return 'card-status-description-container card-status-description-container-' + status;
    },
    getCssDebtorData(haveDebtorData: boolean) : string {
      return haveDebtorData ? 'display: block' : 'display: none';
    },
    convertUtcToLocale(date: Date | string) {
      return moment.utc(date).local().format('DD/MM/YYYY HH:mm:ss').replace(' ', ' - ');
    }
  }
});
