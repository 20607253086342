<template>
  <div id="goBackButton" >
    <div class="flex align-items-center" >
      <div class="mr-2">
        <Button type="Button" class="go-back-button" @click="goBack">
          <i class="pi pi-chevron-left go-back-button-icon"></i>
        </Button>
      </div>
      <div class="lbl-go-back">{{ label }}</div>
    </div>
  </div>
</template>

<script>
import { defineComponent } from 'vue';

export default defineComponent({
  name: 'GoBackButton',
  props: {
    label: String,
  },
  methods: {
    goBack() {
      this.$router.go(-1);
      this.$root.$refs.loadingModal.disable();
    },
  }
});
</script>

<style>

.go-back-button {
  background-color:var(--others-no-background) !important;
  color: var(--navbar-go-back-button-icon-color) !important;
  border: none !important;
  background-repeat:no-repeat;
  border: none;
  cursor:pointer;
  overflow: hidden;
  outline:none;
  padding: 0.5em;
}
.go-back-button:hover{
  background-color:var(--navbar-go-back-button-background-hover-color) !important;
  color: var(--navbar-go-back-button-icon-hover-color) !important;
}
.go-back-button:focus{
  box-shadow: 0px 0px 0px 2px var(--button-go-back-button-focus-shadow-color) !important;
}
.go-back-button-icon {
  font-size: 1.5rem
}

.lbl-go-back
{
  color: var(--navbar-go-back-button-text-color);
  font-weight: bold;
}


</style>
