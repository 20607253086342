<template>

  <div class="line"> 
  </div>

</template>

<script>

export default {
  data() {
    return {
    };
  }
};
</script>

<style scoped>
.line{
  border-top: 1px solid var(--hrline-color);
}
</style>

