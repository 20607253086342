<template>
  <div id="ifLogo" >
    <div class="flex align-items-center" >
      <a @click='goMySite' class="cursor-pointer">
       <div class="mr-2" v-if="ifChoose == 'amz'">
          <img  alt="logo" src="@/assets/logo-basa.png" height="34">
        </div>

        <div class="mr-2" v-if="ifChoose == 'bcd'">
          <img alt="logo" src="@/assets/logo-brasilcard.svg" height="34">
        </div>

        <div class="mr-2" v-if="ifChoose == 'bmx'">
          <img alt="logo" src="@/assets/logo-master.png" height="35">
        </div>

        <div class="mr-2" v-if="ifChoose == 'bs2'">
          <img alt="logo" src="@/assets/logo-bs2.svg" height="34">
        </div>

        <div class="mr-2" v-if="ifChoose == 'ccb'">
          <img alt="logo" src="@/assets/logo-ccb.svg" height="34">
        </div>

        <div class="mr-2" v-if="ifChoose == 'cds'">
          <img alt="logo" src="@/assets/logo-credsis.svg" height="34">
        </div>

        <template v-if="ifChoose == 'exb'">
          <div class="mr-2">
            <img alt="logo" src="@/assets/logo-banco.svg" :width="width" :height="height">
          </div>
          <div class="if-name-text">{{ ifName }}</div>
        </template>

        <div class="mr-2" v-if="ifChoose == 'let'">
          <img alt="logo" src="@/assets/logo-lets.png" height="34">
        </div>

        <div class="mr-2" v-if="ifChoose == 'ori'">
          <img alt="logo" src="@/assets/OI_blue.svg" height="34">
        </div>

        <div class="mr-2" v-if="ifChoose == 'voi'">
          <img alt="logo" src="@/assets/logo-voiter.png" height="34">
        </div>

        <div class="mr-2" v-if="ifChoose == 'zem'">
          <img alt="logo" src="@/assets/logo-zema.png" height="34">
        </div>

        <div class="mr-2" v-if="ifChoose == 'csy'">
          <img alt="logo" src="@/assets/logo-credsystem.png" height="34">
        </div>
      </a>
    </div>
  </div>
</template>

<script>
import { defineComponent } from 'vue';
import { getTheme } from '@/util/util.js';

export default defineComponent({
  name: 'IfInfo',
  props: {
    ifName: String,
    width: Number,
    height: Number
  },
  data() {
    return {
      ifChoose: '',
    };
  },

  methods: {
    goMySite () {
      const requestOptionsforDetails = {
        method: 'GET'
      };
      
      fetch(process.env.VUE_APP_CONSENTS+'/consent/url', requestOptionsforDetails).then(response => {
        response.text().then(data => {
          window.open(data, '_blank').focus();
        });
      });
    }
  },

  created(){
    this.ifChoose = getTheme();
  }
});
</script>

<style scoped>

.if-padding
{
  padding: 0.5em;
}

.if-name-text {
  font-weight: bold;
  color: var(--navbar-go-back-button-text-color);
}

.cursor-pointer {
  cursor: pointer;
  display: flex;
  align-items: center;
}
</style>
