<template>
  <div id="closeButton" >
    <div class="flex align-items-center" >
        <div class="mr-2">
           <Button type="Button" class="close-button" @click="makeAction()">
             <i class="pi pi-times close-button-icon"></i>
           </Button>
        </div>
        <div class="lbl-close">{{ label }}</div>
    </div>
  </div>
</template>

<script>
import { defineComponent } from 'vue';

export default defineComponent({
  name: 'CloseButton',
  props: {
    label: String,
    action: Function
  },
  methods: {
    makeAction(){
      this.$emit('action');
    },
  }
});
</script>

<style scoped>

.close-button {
  background-color:var(--others-no-background);
  background-repeat:no-repeat;
  border: none;
  cursor:pointer;
  overflow: hidden;
  outline:none;
  padding: 0.5em;
}
.close-button-icon {
  font-size: 1.5rem
}
.lbl-close {
  color: var(--navbar-go-back-button-icon-color);
}

</style>
