<template>

  <div class="slidediv">
    <Carousel id="idCarousel">

      <Slide class="slidecl" key="1">

        <div class="wrapper">
          <AboutPage :showOBIcon="true" iconName="proposta" @disable="disable()">
            
            <div class="bodyAboutBox">
              <p class="marg">
                {{internalizationAboutBox.aboutBoxMsg1}}
              </p>
            </div>

            <div class="footerAboutBox">

              <div class="rectangles">
                <PanelRectangle :baserecstyle="baseRec" :recstyle="selectedrec"></PanelRectangle>
                <PanelRectangle :baserecstyle="baseRec" :recstyle="rec"></PanelRectangle>
              </div>

              <Mbutton
                :rotulo="internalizationAboutBox.aboutBoxButtonLabel"
                tipo="button"
                :confirmacao="false"
                class="size80 caroulselButton"
                @botaoAtivado="changePage()"
              />

            </div>

          </AboutPage>
        </div>

      </Slide>

      <Slide class="slidecl" key="2">

        <div class="wrapper">
          <AboutPage :showOBIcon="true" iconName="person" @disable="disable()">

            <div class="bodyAboutBox">
              <p class="marg">
                {{internalizationAboutBox.aboutBoxMsg2}}
              </p>
              <div class="marg">
                <a href="https://openbankingbrasil.org.br/" target="_blank">{{internalizationAboutBox.aboutBoxLinkLabel}}</a>
              </div>
            </div>

            <div class="footerAboutBox">
              <div class="rectangles">
                <PanelRectangle :baserecstyle="baseRec" :recstyle="rec"></PanelRectangle>
                <PanelRectangle :baserecstyle="baseRec" :recstyle="selectedrec"></PanelRectangle>
              </div>
              <Mbutton 
                :rotulo="internalizationAboutBox.aboutBoxButtonLabel"
                tipo="button"
                :style="customButtons"
                :confirmacao="false"
                class="size80 caroulselButton"
                @botaoAtivado="disable()"
              />
            </div>

          </AboutPage>
        </div>

      </Slide>

      <template #addons>
        <Navigation/>
      </template>

    </Carousel>
  </div>
  
</template>

<script>
import 'vue3-carousel/dist/carousel.css';
import { Carousel, Slide, Navigation } from 'vue3-carousel';
import { defineComponent } from 'vue';
import { getInternalization } from '@/util/util.js';

/* Internalization, used if not use BD to get data
var internalizationAboutBoxTmp = {

  "aboutBoxMsg1": "Open Finance é um serviço financeiro que especifica um conjunto de regras sobre o uso e compartilhamento de dados e informações financeiras entre as instituições, proporcionando melhores produtos e serviços financeiros para você.",
  "aboutBoxMsg2": "Se quiser saber mais sobre como o Open Finance funciona e como ele é capaz de mudar para melhor a vida de muitos brasileiros, acesse:",

  "aboutBoxLinkLabel": "Portal do Cidadão",
  "aboutBoxButtonLabel": "CONTINUAR",
  
};
/* */

export default defineComponent({
  name: 'AboutBox',
  props: {
  },
  components: {
    Carousel,
    Slide,
    Navigation
  },
  data(){
    return {
      
      baseRec:{
        'display': 'inline',
        'margin': '4px'
      },
      selectedrec:{
        width: '34px',
        'margin-right': '2px',
        'margin-left': '2px',
        'background-color': 'var(--aboutobcarousel-rectangle-selected-color)'
      },
      rec:{
        width: '6px',
        'background-color': 'var(--aboutobcarousel-rectangle-notselected-color)'
      }
    };
  },
  methods: {
    changePage(){
      document.getElementsByClassName('carousel__next')[0].click();
    },
    disable(){
      this.$emit('disable');
    }
  },
  created(){
    this.internalizationAboutBox = getInternalization();
  },
  mounted(){
    document.getElementsByClassName('carousel__prev')[0].style.display = 'none';
    document.getElementsByClassName('carousel__next')[0].style.display = 'none';
  }
});
</script>

<style scoped>

.wrapper{
  width: 100%;
  height: 100%;
}
.marg {
  margin: 10px 0px 10px 0px;
}
a{
  text-decoration: underline;
  font-weight: 550;
  color: var(--aboutobcarousel-link-color);
}
a:visited{
  font-size: 16px;
  font-weight: 550;
  color: var(--aboutobcarousel-link-color);
}
a:hover, a:active{
  font-weight: bold;
}
.size80 {
  width: 80%;
  margin: 0px auto;
}
.rectangles {
  margin: 10px 0px 20px 0px;
}
.bodyAboutBox {
  margin-bottom: 12px;
}
.bodyAboutBox p{
  color: var(--aboutobcarousel-text-color);
  font-size: var(--aboutobcarousel-text-size) !important;
}
.novaAba {
    target-new: tab ! important
}

</style>