<template>

  <div id="aboutBoxDisable" class="disableScreen" v-if="showAboutBox"></div>

  <div id="aboutBox" class="boxCentered" v-if="showAboutBox">
      
    <AboutBox @disable="disable()"/>

  </div>
  
</template>

<script>
import { defineComponent } from 'vue';

export default defineComponent({
  data(){
    return {
      showAboutBox: false,
    };
  },
  methods: {
    enable(){
      this.showAboutBox = true;
    },
    disable(){
      this.showAboutBox = false;
    }
  }
});
</script>

<style scoped>

.disableScreen{
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.5);
}
.boxCentered {
  position: fixed;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);

  text-align: center;
  padding: 0px;
  background: transparent;
  box-sizing: border-box;
  box-shadow: 0px 4px 32px rgba(128, 128, 128, 0.3);
  border-radius: 15px;
  width: 70%;
}

</style>