<template>

  <Button :type="tipo" @click="disparaAcao()" :style="customButtons" class="buttonpattern">
    <span>{{rotulo}}</span>
  </Button>

</template>

<script>
export default {
  data(){
    return {
      customButtons:{
      },
      bgcolor:''
    };
  },
  created(){
    this.customButtons={
      'font-style': 'normal',
      'font-weight': '700',
      'font-size': 'ze)',
      'padding-top': '16px',
      'padding-bottom': '16px'
    };
  },
  name: 'MbuttonModal',
  props: {
    tipo: {
      type: String,
      required: true
    },
    rotulo: {
      type: String,
      required: true
    },
    confirmacao: Boolean,
  },
  methods: {

    disparaAcao() {

      if (this.confirmacao) {

        if (confirm('Confirma operacao?')){
          this.$emit('botaoAtivado');
        }
        return;
      }
      this.$emit('botaoAtivado');
    }
  }
};
</script>

<style scoped>
.buttonpattern{
  line-height: 24px;
  background-color: var(--button-confirmconsent-modal-background-color);
  font-size: var(--button-confirmconsent-text-size);
  border: var(--button-confirmconsent-border);
  border-radius: var(--button-confirmconsent-border-radius);
  color: var(--button-confirmconsent-modal-text-color);
  display: block;
  box-shadow: var(--button-confirmconsent-shadow);
  width: 100%;
}
.buttonpattern:focus, .buttonpattern:active {
  outline: none !important;
  box-shadow: var(--button-confirmconsent-shadow);
}

.buttonpattern:hover {
  background: var(--button-confirmconsent-modal-background-hover-color) !important;
  border: var(--button-confirmconsent-border) !important;
  color: var(--button-confirmconsent-modal-text-color) !important;
}

.caroulselButton{
  background-color: var(--button-carousel-background-color) !important;
  border: var(--button-carousel-border) !important;
  color: var(--button-carousel-text-color) !important;
  box-shadow: var(--button-carousel-shadow) !important;
}
.caroulselButton:hover{
  background-color: var(--button-carousel-background-hover-color) !important;
  border: var(--button-carousel-border) !important;
  color: var(--button-carousel-text-color) !important;
}

.modalConfirm{
  background-color: var(--button-modal-confirm-background-color) !important;
  font-size: var(--button-modal-confirm-text-size) !important;
  border: var(--button-modal-confirm-border) !important;
  color: var(--button-modal-confirm-text-color) !important;
  box-shadow: var(--button-modal-confirm-shadow) !important;
}

.modalConfirm:hover{
  background-color: var(--button-modal-confirm-background-hover-color) !important;
  border: var(--button-modal-confirm-border) !important;
  color: var(--button-modal-confirm-text-color) !important;
}

.accountconfirmbutton{
  background-color: var(--button-confirmaccount-background-color) !important;
  border: var(--button-confirmaccount-border) !important;
  color: var(--button-confirmaccount-text-color) !important;
  box-shadow: var(--button-confirmaccount-shadow) !important;
}
.accountconfirmbutton:hover{
  background-color: var(--button-confirmaccount-background-hover-color) !important;
  border: var(--button-confirmaccount-border) !important;
  color: var(--button-confirmaccount-text-color) !important;
}

.modalCalendarFilter{
  display: inline-block;
  width: 40%;
  height: 60px;
  background-color: var(--button-filterclient-filter-background-color) !important;
  border: var(--button-filterclient-filter-border) !important;
  color: var(--button-filterclient-filter-text-color) !important;
  box-shadow: var(--button-filterclient-filter-shadow) !important;
}
.modalCalendarFilter:hover{
  background-color: var(--button-filterclient-filter-background-hover-color) !important;
  border: var(--button-filterclient-filter-border) !important;
  color: var(--button-filterclient-filter-text-color) !important;
}

</style>