import { toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-67994d60"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "mb-2" }
const _hoisted_2 = { class: "ml-5 mr-5" }
const _hoisted_3 = {
  key: 0,
  class: "text text-title fontP"
}
const _hoisted_4 = {
  key: 1,
  class: "text text-title"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      (_ctx.title == 'Simulador de TPP')
        ? (_openBlock(), _createElementBlock("h3", _hoisted_3, _toDisplayString(_ctx.title), 1))
        : _createCommentVNode("", true),
      (_ctx.title != 'Simulador de TPP')
        ? (_openBlock(), _createElementBlock("h3", _hoisted_4, _toDisplayString(_ctx.title), 1))
        : _createCommentVNode("", true)
    ])
  ]))
}