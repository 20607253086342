<template>
    
  <div class="box">
    <svg>
      <circle cx="20" cy="20" r="20"></circle>
      <circle id="fillCircle" cx="20" cy="20" r="20"></circle>
    </svg>
  </div>

</template>

<script>

const splitByN = 1000;

export default {
  data() {
    return {
    };
  },
  methods: {
    getCircle(){
      const circle = document.getElementById('fillCircle');
      return circle;
    },
    rotateSample(){
      const circle = document.getElementById('fillCircle');
      for(let i=1; i<=500; i++)
        setTimeout( function(){ circle.style.strokeDashoffset = 200 - (i/500*200);}, (i * 10));
    }
  }
};
</script>

<style scoped>
.box{
  display: inline-block;
  position: relative;
  width: 50px;
  height: 50px;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}
.box svg{
  position: relative;
  transform: rotate(-90deg);
  width: 50px;
  height: 50px;
}
.box svg circle{
  width: 50px;
  height: 50px;
  fill: none;
  stroke-width: 6px;
  transform: translate(5px,5px);
  stroke-dasharray: 200;
  stroke-dashoffset: 200;
}
.box svg circle:nth-child(1){
  stroke-dashoffset: 0;
  stroke: var(--redirect-loading-background-color);
}
.box svg circle:nth-child(2){
  stroke-dashoffset: 200;
  stroke: var(--redirect-loading-fill-color);
}
</style>