<template>

  <BModal ref="bmodal">
    <!-- <div class="divCloseBtn">
      <span class="closeBtn" @click="disable()"><i class="pi pi-times"></i></span>
    </div> -->
    <div class="header">{{header}}</div>
    <div class="space"></div>

    <div v-if="isCancel">
      <span class="description">
        {{part1}}
      </span>

      <span class="description descriptionBold">
        {{part2}}
      </span>

      <span class="description">
        {{part3}}
      </span>
      
      <span class="description descriptionRed">
        {{part4}}
      </span>

      <span class="description">
        {{part5}}
      </span>

      <div class="space"></div> 
    </div>

    <div v-else>
      <div class="description descriptionBold">{{description}}</div>
      <div class="space"></div> 
    </div>

    <div class="message">
    </div>
    <div class="mb-2">
          <div class="description flex flex-column container" style="display:flex">
            <h4 class='font-w'>{{textareaTitle}}</h4>
            <Textarea
              v-if="showTextarea" 
              class="textarea" 
              v-model="message"
              v-on:keyup="countdown"
              :autoResize="true" 
              rows="10" 
              cols="120" 
              style="flex:1" 
              maxlength="140"
              :placeholder="textareaPlaceholder"/>
              <p class="description-countdown-character" v-if="showTextarea">
                Permite <span>{{revokeMessageRemainingCount}}</span> caracteres.
              </p>
          </div>
        </div>
      
    <Mbutton
      :rotulo="fstButtonTxt"
      tipo="button"
      class="modalConfirm"
      :confirmacao="false"
      @botaoAtivado="makeAction()"
    />
    <div class="space"></div>

    <Bbutton
      :rotulo="sndButtonTxt"
      tipo="submit"
      class="modalCancel"
      :confirmacao="false"
      @botaoAtivado="disable()"
    />
      
  </BModal>

</template>

<script>

export default {
  props: {
    header: String,
    isCancel: Boolean,
    boldString: String,
    redString: String,
    description: String,
    textareaTitle: String,
    showTextarea: Boolean,
    textareaPlaceholder: String,
    fstButtonTxt: String,
    sndButtonTxt: String,
    action: Function
  },
  data() {
    return {
      showModal: false,
      part1:'',
      part2:'',
      part3:'',
      message: '',
      revokeMessageMaxCount: 140,
      revokeMessageRemainingCount: 140,
    };
  },
  methods: {
    makeAction(){
      this.$emit('action');
    },
    enable(){
      this.$refs.bmodal.enable();
    },
    disable(){
      this.message = '';
      this.$refs.bmodal.disable();
    },
    isEmpty(text){
      if (text === 'undefined' || text == null) 
        return true;

      return text.replace(/\s/g, '').length < 1;
    },
    countdown() {
      this.revokeMessageRemainingCount = this.revokeMessageMaxCount - this.message.length;
    },
  },
  created(){
    const holder = this.description;
    if (this.isCancel){
      if (holder.includes(this.boldString) && !this.isEmpty(this.boldString)){
        const boldParts = holder.split(this.boldString);
        const redParts = boldParts[1].split(this.redString);
        this.part1=boldParts[0];
        this.part2=this.boldString;
        this.part3=redParts[0];
        this.part4=this.redString;
        this.part5=redParts[1];
      }
      else{
        this.part1=holder;
        this.part2='';
        this.part3='';
      }
    }
  }
};
</script>

<style scoped>

.header{
  margin-top: 8px;
  font-style: normal;
  font-weight: bold;
  font-size: 24px;
  line-height: 32px;
  color: var(--confrevoke-modal-main-title-color);
}
.description{
  font-size: 16px;
  line-height: 24px;
  padding: none;
  margin-bottom: 4px;
  color: var(--confcons-modal-text-color);
}
.description p{
  font-size: 14px;
  text-align: left;
  margin: 8px;
}
.message{
  font-size: 16px;
  line-height: 24px;
  width: 100%;
  padding: none;
  margin-bottom: 4px;
  color: var(--confcons-modal-text-color);
}
.space{
  padding: none;
  margin: none;
  height: 14px;
}
.descriptionBold{
  font-weight: var(--confcons-modal-cancel-bold-not-weight) !important;
  color: var(--confcons-modal-cancel-bold-not-color);
}
.descriptionRed{
  font-weight: var(--confcons-modal-cancel-bold-not-weight) !important;
  color: var(--confrevoke-modal-cancel-bold-not-color) !important;
}
.textarea{
  border: 1px solid var(--checkbox-border-color);
  color: var(--confrevoke-textarea-color);
  border-radius: 8px;
}
.textarea:hover, .textarea:checked {
  box-shadow: var(--shadow-default) !important;
}
.divCloseBtn{
  text-align: left;
  margin-bottom: 5px;
  color: var(--confrevoke-modal-main-title-color);
}
.font-w {
  font-weight: var(--cons-revoke-action-text-weight) !important;
}

.description-countdown-character {
  font-size: 12px;
  color: #3C3C3C;
}
</style>