
import { PropType, defineComponent } from 'vue';
type CalendarValueType = Date | Date[] | undefined;

export default defineComponent({
  name: 'ConsentStatusCardDetail',
  props: {
    id: Number,
    detailView: String,
    cardStatus: {
      type: String,
      required: true
    },
    cardStatusTitle: String,
    cardStatusDescription: String,
    cardInfoTitle: String,
    cardInfoDescription: String,
    initiatorTitle: String,
    initiatorName: String,
    payerDetailTitle: String,
    payerCpf: String,
    payerName: String,
    payerBranch: String,
    payerCc: String,
    localInstrument: String,
    beneficiaryDetailTitle: String,
    beneficiaryCnpj: String,
    beneficiaryName: String,
    beneficiaryPixLabel: String,
    beneficiaryPixValue: String,
    beneficiaryBankLabel: String,
    beneficiaryBankCode: String,
    beneficiaryAggLabel: String,
    beneficiaryAgg: String,
    beneficiaryAccount: String,
    beneficiaryQRDinLabel: String,
    beneficiaryQREstLabel: String,
    consentCreatorLabel: String,
    consentCreatorName: String,
    consentRejectionReasonCodeLabel: String,
    consentRejectionReasonCode: String,
    creationDateTitle: String,
    initiationDateTitle: String,
    scheduledDateTitle: String,
    revocationReasonTitle: String,
    revocationReason: String,
    creationDate: String,
    initiationDate: String,
    scheduledDate: String,
    valueTitle: String,
    value: String,
    paymentFormTitle: String,
    paymentForm: String,
    consentIDTitle: String,
    consentID: String,
    haveDebtorAccount: Boolean,
    payFeeTitle: String,
    payFee: String,
    purposeTitle: String,
    purpose: String,
    typeScheduleV4: String,
    v4NameWeek: String,
    v4DayMonth: Number,
    recurrence: Number,
    scheduledDatev4: {
      type: Object as PropType<CalendarValueType>
    },
    customMsgInfo: String
  },

  data() {
    return {
      customCalendar: false,
      customButton: {
        'font-style': 'normal',
        'font-weight': '700',
        'font-size': 'var(--button-confirmconsent-text-size)',
        'padding-top': '16px',
        'padding-bottom': '16px'
      }
    };
  },

  computed: {
    scheduledDatev4Local: {
      get: function() : CalendarValueType {
        if (Array.isArray(this.scheduledDatev4)) {
          return this.scheduledDatev4.map(date => date instanceof Date ? date : new Date(date));
        } else if (this.scheduledDatev4 instanceof Date) {
          return this.scheduledDatev4;
        } else {
          return undefined;
        }
      },
      set: function() : void {
        console.warn('não permitido');
      }
    }
  },

  methods: {
    test(message: string) : void {
      console.log(message);
    },
    getCssClassBox(status: string) : string {
      return 'grid box box-' + status;
    },
    getCssClass(status: string) : string {
      return 'col-12 card-header card-header-' + status;
    },
    getCssClassToggler(status: string) : string {
      return 'm-auto toggler toggler-' + status;
    },
    getCssClassDescriptionContainer(status: string) : string {
      return 'card-status-description-container card-status-description-container-' + status;
    },
    getCssDebtorData(haveDebtorData: boolean) : string {
      return haveDebtorData ? 'display: block' : 'display: none';
    },
    goToView(viewName: string, params?: Record<string, string | number | (string | number)[] | null | undefined>) : void {
      this.$router.push({ name: viewName, params});
    },

    typeRecurrence () : string {
      switch (this.typeScheduleV4) {
        case 'daily':
          return 'diário';
        case 'weekly':
          return 'semanal';
        case 'monthly':
          return 'mensal';
        case 'custom':
          return 'custom';
          default: return 'não definido';
      }
    },

    formatDayWeek () : string {
      switch (this.v4NameWeek) {
        case 'SEGUNDA_FEIRA':
          return 'segunda-feira';
        case 'TERCA_FEIRA':
          return 'terça-feira';
        case 'QUARTA_FEIRA':
          return 'quarta-feira';
        case 'QUINTA_FEIRA':
          return 'quinta-feira';
        case 'SEXTA_FEIRA':
          return 'sexta-feira';
        case 'SABADO':
          return 'sábado';
        case 'DOMINGO':
          return 'domingo';
        default: return 'não definido';
      }
    }
  }
});
