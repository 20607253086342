<template>
  <div class="disableScreen" v-if="showModal">

    <div class="errDetail2">
      <div class="errIconMsg2">
        <div class="centered">
        </div>
      </div>
      <div>
        <div class="space2"></div>
        <div class="errIconMsgFont">{{messageErr}}</div>
        <div class="space2"></div>
        <div style="margin-bottom: 20px" class="errDetailFont">{{detailErr}}</div>
        <div v-if='detailErr2' class="errDetailFont">{{detailErr2}}</div>
        <div v-if='detailErr2' class="space2"></div>
        <Mbutton
          class="button-ok"
          rotulo="Ok"
          tipo="submit"
          @botaoAtivado="disable()"
        />
        <div class="space2"></div>
      </div>
    </div>

  </div>

</template>

<script>

function goBack() {
  window.history.back();
}
export default {
  name: 'ConfirmModal',
  props: {
    tipo: String,
    messageErr: String,
    detailErr: String,
    detailErr2: String
  },
  data() {
    return {
      showModal: false,
      
    };
  },
  methods: {
    enable(){
      this.showModal = true;
    },
    disable(){
      this.showModal = false;
    },
  },
  created(){
      // clearTimeout(timeout);
      const timeout = setTimeout(() => {  
        if(this.showModal){
          window.history.back();
        }
      },  30000);
  }
};

</script>

<style scoped>
.disableScreen{
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background: var(--exceptions-background-gradient);
  display: flex;
  flex-direction: column;
}
.errIconMsg, .errIconMsg2, .errDetail, .errDetail2{
  max-width: 800px;
}
.errIconMsgFont {
  font-style: normal;
  font-weight: bold;
  font-size: 24px;
  line-height: 33px;
  color: var(--info-modal-myconsents-label-color);
}
.errDetailFont {
  color: var(--info-modal-myconsents-text-color);
}
.errDetail, .errDetail2{
  font-size: 18px;
  line-height: 24px;
  background-color: var(--exceptions-backgrounddown-solid-color);
  border-radius: 8px;
  -webkit-transition: top 1s;
  -moz-transition: top 1s;
}
.errDetail2{
  text-align: -webkit-center;
  text-align: -moz-center;
  flex-shrink: 0;
  box-shadow: var(--shadow-default);
  margin: auto;
  width: 60%;
  border: var(--modal-border-infos)
}
.centered{
  display: flex;
  justify-content: center;
  flex-direction: column;
  height: 100%;
}
.space{
  padding: none;
  margin: none;
  height: 40%;
}
.space2{
  padding: none;
  margin: none;
  height: 40px;
}

.button-ok {
  background-color: var(--info-modal-myconsents-background-ok-color);
  color: var(--info-modal-myconsents-ok-color);
  max-width: 144px;
}

.button-ok:hover {
  background-color: var(--info-modal-myconsents-background-ok-color) !important;
  color: var(--info-modal-myconsents-ok-color) !important;
}
</style>