
import { defineComponent } from 'vue';

export default defineComponent({
  name: 'NavBar',

  props: {
    ifName: String,
    bntGoBackLabel: String,
  },

  data() {
    return {
      managerNav: false,
      showManagerNav: false,
      showGoBack: true,
    };
  },

  methods: {
    showClientNavbar(){
      this.managerNav = false;
      this.showManagerNav = true;
    },
    hideClientNavbar(){
      this.managerNav = false;
      this.showManagerNav = false;
    },
    showManagerNavbar(){
      this.managerNav = true;
      this.showManagerNav = true;
    },
    showGoBackButton() {
      this.showGoBack = true;
    },
    hideGoBackButton() {
      this.showGoBack = false;
    },
  }
});
