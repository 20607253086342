
import { defineComponent } from 'vue';

export default defineComponent({
  name: 'ConsentStatusCard',
  props: {
    id: String,
    detailView: String,
    cardStatus: String,
    cardStatusTitle: String,
    cardStatusDescription: String,
    cardInfoTitle: String,
    cardInfoDescription: String,
    cardHistoryAwaitingDate: String,
    isSweepingDetail: Boolean
  },
  methods: {
    test(message: string) : void {
      console.log(message);
    },
    getCssClass(status: string) : string {
      return 'col-12 card-header card-header-' + status;
    },
    goToView(viewName: string, id: string) : void {
      sessionStorage.setItem('historyAwaitingDate', this.cardHistoryAwaitingDate ? this.cardHistoryAwaitingDate.toString() : 'invalid date');
      sessionStorage.setItem('LAST_SELECTED_CONSENT', id);
      sessionStorage.setItem('isSweepingCheck', this.isSweepingDetail.toString());
      this.$router.push({ name: 'DetailConsent', params: { detailView: viewName } });
    },
  }
});
