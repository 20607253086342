import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-8394b5b4"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "mb-2" }
const _hoisted_2 = { class: "grid box" }
const _hoisted_3 = { class: "card card-status-title mb-2 ml-2" }
const _hoisted_4 = { class: "card card-status-description ml-2" }
const _hoisted_5 = { class: "col-12 whitebox" }
const _hoisted_6 = { class: "card card-info card-info-title mb-1 ml-2" }
const _hoisted_7 = { class: "card card-info card-info-description mb-1 ml-2" }
const _hoisted_8 = { class: "col-12 flex justify-content-between" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("a", null, [
      _createElementVNode("div", _hoisted_2, [
        _createElementVNode("div", {
          class: _normalizeClass(_ctx.getCssClass(_ctx.cardStatus)),
          onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.goToView('resume', _ctx.id))),
          style: {"cursor":"pointer"}
        }, [
          _createElementVNode("p", _hoisted_3, _toDisplayString(_ctx.cardStatusTitle), 1),
          _createElementVNode("p", _hoisted_4, _toDisplayString(_ctx.cardStatusDescription), 1)
        ], 2),
        _createElementVNode("div", _hoisted_5, [
          _createElementVNode("p", _hoisted_6, _toDisplayString(_ctx.cardInfoTitle), 1),
          _createElementVNode("p", _hoisted_7, _toDisplayString(_ctx.cardInfoDescription), 1)
        ]),
        _createElementVNode("div", _hoisted_8, [
          _createElementVNode("span", {
            class: "card card-info card-info-link mb-1 ml-2",
            onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.goToView('resume', _ctx.id)))
          }, "Resumo"),
          _createElementVNode("span", {
            class: "card card-info card-info-link mb-1 mr-2",
            onClick: _cache[2] || (_cache[2] = ($event: any) => (_ctx.goToView('history', _ctx.id)))
          }, "Histórico")
        ])
      ])
    ])
  ]))
}