<template>

    <Button class="editbutton" :type="tipo" @click="disparaAcao()" :style="customButtons">
      {{rotulo}}
      <svg width="17" height="17" viewBox="0 0 17 17" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path :fill="fillIconColor" fill-rule="evenodd" clip-rule="evenodd" d="M15.6609 3.46908L13.5275 1.33838C12.7421 0.553874 11.4685 0.553874 10.6831 1.33838L1.18436 10.8253C1.01979 10.9897 0.917397 11.2059 0.894627 11.4373L0.504825 15.3972C0.49823 15.4642 0.498396 15.5317 0.505322 15.5987C0.562387 16.1505 1.05652 16.5516 1.609 16.4946L5.55242 16.0878C5.78222 16.0641 5.99685 15.9621 6.16021 15.7989L15.6609 6.31003C16.4464 5.52552 16.4464 4.25359 15.6609 3.46908ZM10.3707 3.07088L13.9263 6.62206L14.9498 5.59979C15.3425 5.20754 15.3425 4.57157 14.9498 4.17932L12.8164 2.04861C12.4237 1.65636 11.7869 1.65636 11.3942 2.04861L10.3707 3.07088ZM10.9395 5.3436L9.51723 3.92313L2.11691 11.3143L3.53915 12.7347L10.9395 5.3436ZM11.7931 6.19592L4.39273 13.5871L5.67274 14.8655L13.0731 7.47435L11.7931 6.19592ZM1.50552 15.4954L1.78221 12.6846L4.30715 15.2064L1.50552 15.4954Z"/>
      </svg>
    </Button>

</template>

<script>
  export default {
    data(){
      return {
        fillIconColor: 'var(--confcons-box2-edit-icon-color)',
        customButtons:{
        }
      };
    },
    created() {
      this.customButtons = {
        backgroundColor:'var(--others-no-background)',
        color:'var(--confcons-box2-edit-text-color)',
        border: 'none',
        padding: '2px',
        'font-size': 'var(--confcons-box2-edit-font-size)',
      };
    },
    name: 'Editbutton',
    props: {
        tipo: String,
        rotulo: String,
        width: String,
        height: String
    },
    methods: {
      disparaAcao() {
        this.$emit('botaoAtivado');
      }
    },
  };
</script>

<style scoped>

.editbutton:active{
  border: none;
  outline: none;
}
.editbutton:hover{
  font-weight: bold;
}

</style>