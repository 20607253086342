<template>

  <Button :type="tipo" @click="disparaAcao()" :style="customButtons" class="buttonpattern" >
    {{rotulo}}
  </Button>

</template>

<script>
export default {
  data(){
    return {
      customButtons:{
      }
    };
  },
  created() {
    this.customButtons={
      'font-style': 'normal',
      'font-weight': '700'
    };
  },
  name: 'Mbutton',
  props: {
    tipo: {
      type: String,
      required: true
    },
    rotulo: {
      type: String,
      required: true
    },
    confirmacao: Boolean,
  },
  methods: {
    disparaAcao() {

      if (this.confirmacao) {

        if (confirm('Confirma operacao?')) {
          this.$emit('botaoAtivado');
        }
        return;
      }
      this.$emit('botaoAtivado');
    }
  }
};
</script>

<style scoped>

.buttonpattern{
  border-radius: 8px;
  display: block;
  padding-top: 16px;
  padding-bottom: 16px;
  width: 100%;
}

.modalCancel{
  background-color: var(--button-modal-cancel-background-color) !important;
  border: var(--button-modal-cancel-border) !important;
  color: var(--button-modal-cancel-text-color) !important;
  font-size: var(--button-modal-cancel-text-size) !important;
  box-shadow: var(--button-modal-cancel-shadow) !important;
}
.modalCancel:hover{
  background-color: var(--button-modal-cancel-background-hover-color) !important;
  border: var(--button-modal-cancel-border) !important;
}

.modalCalendarClear{
  display: inline-block;
  width: 40%;
  height: 60px;
  margin-right: 40px;
  background-color: var(--button-filterclient-clear-background-color) !important;
  border: var(--button-filterclient-clear-border) !important;
  color: var(--button-filterclient-clear-text-color) !important;
  box-shadow: var(--button-filterclient-clear-shadow) !important;
}
.modalCalendarClear:hover{
  background-color: var(--button-filterclient-clear-background-hover-color) !important;
  border: var(--button-filterclient-clear-border) !important;
}

</style>