<template>

  <BModal ref="bmodal">

    <div class="space"></div>
      
    <div>
      <div class="description">{{description}}</div>
    </div>

    <div class="space"></div>

  </BModal>

</template>

<script>

export default {
  props: {
    description: String
  },
  data() {
    return {
      showModal: false
    };
  },
  methods: {
    enable(){
      this.$refs.bmodal.enable();
    },
    disable(){
      this.$refs.bmodal.disable();
    },
    isEmpty(text){
      if (text === 'undefined' || text == null) 
        return true;

      return text.replace(/\s/g, '').length < 1;
    }
  }
};
</script>

<style scoped>


.description{
  font-size: 16px;
  line-height: 24px;
  padding: none;
  margin-bottom: 4px;
  color: var(--confcons-modal-text-color);
}

.space{
  padding: none;
  margin: none;
  height: 14px;
}
</style>