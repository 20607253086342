
import { defineComponent } from 'vue';

export default defineComponent({
  name: 'TecbanSimpleCardShadow',
  props: {
    msg: String,
    cardLabel: String,
    action: Function
  },
  methods: {
    showMsg(message: string) : void {
      console.log(message);
    },
    makeAction(){
      this.$emit('action');
    },
  }
});
