<template>
    <div v-if="showModal" class="modal-overlay">
      <div class="modal-container">
        <div class="modal-header">
          <h2 class="title">{{ title }}</h2>
          <span @click="closeModal" class="closeBtn"><i class="pi pi-times" data-v-953a95b8=""></i></span>
        </div>
        <div class="sub-title">
          <span>Escolha a data de início:</span>
        </div>
        <div class="modal-content">
          <Calendar
              ref="fCalendar"
              class="filterPCalendar"
              v-model="dateModel"
              :inline="true"
              :showWeek="false"
          />
          <div class="container-btn">
            <button class="btn-exportar mb-2" @click="exportDatePayments(dateModel, 1)">Exportar 1 mês</button>
            <!-- Esse botão vai ser utilizado futuramente -->
            <!-- <button class="btn-exportar" @click="exportDatePayments(dateModel, 6)">Exportar 6 meses</button> -->
          </div>
        </div>
      </div>
    </div>
  </template>
  
  <script>
  import axios from 'axios';

  export default {

    data() {
      return {
        dateModel: new Date(),
      };
    },
    props: {
      showModal: {
        type: Boolean,
        required: true,
      },
      title: {
        type: String,
        default: 'Exportar dados de pagamento',
      },
    },
    methods: {
      closeModal() {
        this.$emit('close');
      },
      exportDatePayments(endDate, interval) {
        this.$root.$refs.loadingModal.enable();
        const originalDate = new Date(endDate);

        const year = originalDate.getFullYear();
        const month = ('0' + (originalDate.getMonth() + 1)).slice(-2);
        const day = ('0' + originalDate.getDate()).slice(-2);

        const formattedDate = year + '-' + month + '-' + day;

        const apiUrl = `${process.env.VUE_APP_CONSENTS}/report/payments`;

        const params = new URLSearchParams({
            endDate: formattedDate,
            interval: interval
        });

        axios.get(apiUrl, {
            params: params,
            responseType: 'blob'
        })
        .then(response => {
          this.$root.$refs.loadingModal.disable();
          this.downloadCSV(response.data, formattedDate, interval);
        })
        .catch(error => console.log(error));
      },
      downloadCSV(csvData, endDate, interval) {
        const blob = new Blob([csvData], { type: 'text/csv' });

        const link = document.createElement('a');
        link.href = window.URL.createObjectURL(blob);
        link.download = this.generateFileName(endDate, interval);

        document.body.appendChild(link);
        link.click();

        document.body.removeChild(link);
      },
      generateFileName(date, interval) {
        const data = new Date(date);
        const formattedInitialDate = `${data.toISOString().split('T')[0].split('-').reverse().join('-')}`;
        data.setMonth(data.getMonth() - interval);
        const formattedFinalDate = data.toISOString().split('T')[0].split('-').reverse().join('-');

        const reportName = `Relatorio_Pagamentos_${formattedFinalDate}_${formattedInitialDate}.csv`;
        return reportName;
      }
    },
  };
  </script>
  
  <style scoped>
  .modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 99;
  }
  
  .modal-container {
    background: #fff;
    padding: 20px;
    border-radius: 8px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.3);
  }
  
  .modal-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  
  .modal-header button {
    background: #ddd;
    border: none;
    padding: 5px 10px;
    cursor: pointer;
  }

  .closeBtn {
    font-style: normal;
    font-size: 24px;
    line-height: 32px;
    font-weight: normal;
    color: #F20C0C;
    cursor: pointer;
    margin-bottom: 1rem;
  }

  .title {
    font-size: 18px;
    font-weight: 700;
    margin: 0 0 1rem 0;
  }

  .sub-title{
    margin-bottom: 1rem;
    font-size: 18px;
  }

  .container-btn {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }

  .btn-exportar {
    width: 95%;
    font-style: normal;
    font-weight: 700;
    padding-top: 16px;
    padding-bottom: 16px;
    background-color: var(--button-modal-confirm-background-color) !important;
    font-size: var(--button-modal-confirm-text-size) !important;
    border: var(--button-modal-confirm-border) !important;
    color: var(--button-modal-confirm-text-color) !important;
    box-shadow: var(--button-modal-confirm-shadow) !important;
    border-radius: var(--button-confirmconsent-border-radius);
    cursor: pointer;
  }
  </style>