<template>

  <section ref="modalConfirm" class="bStyle">

    <h2 class="title textbox1">
      {{internalizationConfCons.shareConfirmModalHeader}}
    </h2>

    <GreyContainer class="boxGrey">
      <p class="text textbox1">
        {{internalizationConfCons.shareConfirmModalDescription}}
      </p>
      <div class="title titlebox1">{{tppName}}</div>
    </GreyContainer>

    <div class="title textbox1">{{internalizationConfCons.shareConfirmModalResumeTitle}}</div>

    <PanelWBorder 
      :pstyle="panel" 
      :ptstyle="panelTitle" 
      :ftype="fTypetmp"
      :ftype2="fTypetmp2" 
      :pcstyle="panelContent">
    
      <PanelRectangle
        :baserecstyle="baseRec"
        :recstyle="rec"
        style="display: var(--confcons-box2-up-rectangle-display)">
      </PanelRectangle>
      <!-- Identificação do cliente -->
      <div class="subtitle box2innertitle">{{internalizationConfCons.shareConfirmModalResumeIdClient}}</div>
      <div class="text box2innertext weight">{{ typeDocument }} {{document}}</div>
      <div class="text box2innertext weight">{{shareDetail.name}}</div>
      
      <!-- Data da criação -->
      <div class="subtitle box2innertitle">{{internalizationConfCons.shareConfirmModalResumeDate}}</div>
      <div class="text box2innertext weight">{{creationDate}}</div>
      
      <!-- Insituição de destino -->
      <div class="subtitle box2innertitle">{{internalizationConfCons.shareConfirmModalResumeIfName}}</div>
      <div class="text box2innertext weight">{{ifName}}</div>
      
      <!-- Prazo do compartilhamento -->
      <div class="subtitle box2innertitle">{{internalizationConfCons.shareConfirmModalResumeExpirationDate}}</div>
      <div class="text box2innertext weight">Encerra em: {{expirationDate}}</div>

      <!-- ID do consentimento -->
      <div class="subtitle box2innertitle">{{internalizationConfCons.shareConfirmModalResumeIdConsent}}</div>
      <div class="text box2innertext weight">{{consentDetails ? consentDetails.data.id : null}}</div>
      
    </PanelWBorder>

    <GreyContainer class="boxGrey box-grey-center">
      <!-- internacionalizar -->
      <div class="title textbox1">{{internalizationConfCons.shareConfirmModalResumeWarning}}</div>
      <p class="text textbox1">
        {{internalizationConfCons.shareConfirmModalResumeWarningDescription}}
      </p>
      
    </GreyContainer>
    
    <div style="mt-4">

      <Mbutton
        :rotulo="internalizationConfCons.shareConfirmModalBtnConfirm"
        tipo="button"
        :confirmacao="false"
        @botaoAtivado="confirmShow()"
      />

      <Cbutton class="mt-3 shareBtnBorder"
        :rotulo="internalizationConfCons.shareConfirmModalBtnCancel"
        tipo="submit"
        :confirmacao="false"
        @botaoAtivado="cancelFirstShow()"
      />
    </div>

  </section>

  <ConfirmCancelModal
    ref="confirmBox"
    :isCancel="false"
    :header="internalizationConfCons.shareConfirmModalResumeSureHeader"
    :description="internalizationConfCons.shareConfirmModalResumeSureDescription"
    :warning="false"
    :fstButtonTxt="internalizationConfCons.shareConfirmModalResumeSureBtnConfirm"
    :sndButtonTxt="internalizationConfCons.shareConfirmModalResumeSureBtnConfirmSnd"
    @action="confirmConsent()"
  />

  <ExceptionModal
    ref="exceptionNotDataAuthorize"
    tipo='withAccLink'
    messageErr=""
    :detailErr="internalizationConfCons.confConsErrConsNotDataAuthorizeMsg"
    :detailErr2="internalizationConfCons.confConsErrConsNotAwaitingLinkLabel"
  />

</template>

<script>
Date.prototype.addHours = function(h){
  this.setHours(this.getHours()+h);
  return this;
};

import { getWithExpiry, getInternalization, MaskCpfCnpjBlur } from '@/util/util.js';
import { getTheme } from '@/util/util.js';

/* eslint-disable */
export default {

  props: {
    consentDetailsConfirm: {
      type: Object,
      required: true
    }
  },

  data() {
    return {
      ifName: '',
      businessName: '',
      document: '',
      typeDocument: '',
      tppName: null,
      shareDetail: {},
      expirationDate: {},
      creationDate: {},
      redirectRoute: 'Redirect',
      consentDetails : {},
      /* stilos de componentes personalizados */
      baseRec:{
      },
      rec:{
        'background-color': 'var(--confcons-box2-up-hrrectangle-color)'
      },
      panel:{
        'border': '1px solid var(--confcons-box2-border-color)',
        'background-color': 'var(--confcons-box2-up-background-color)',
        'box-shadow': 'var(--confcons-box2-shadow)',
        'filter': 'var(--confcons-box2-filter)',
        'margin': '30px 0'
      },
      panelTitle:{
        'display':'none'
      },
      panelContent:{
        'background': 'var(--confcons-box2-down-background-color)',
        'border': '1px solid var(--confcons-box2-border-color)',
        'padding': '0px 15px 15px 15px'
      },
      fTypetmp:{
        'font-size': 'var(--confcons-box2-up-amount-title-text-size)',
        'font-weight': 'var(--confcons-box2-up-amount-title-font-weight)',
        'color': 'var(--confcons-box2-up-amount-title-text-color)'
      },
      fTypetmp2:{
        'font-size': '24px',
        'font-weight': 'var(--confcons-box2-up-amount-title-font-weight)',
        'color': 'var(--confcons-box2-up-amount-title-value-color)',
      }
      /* */
    }
  },
  methods: {
    confirmShow(){
      this.$refs.confirmBox.enable();
    },
  
    cancelFirstShow(){
      this.$emit('cancelFirstShow');
    },

    enable(){
      // this.updateDescription()
      this.$refs.ConfirmCancelModal.enable();
    },   
    disable(){
      this.$refs.ConfirmCancelModal.disable();
    },
    maskCpfCnpj(cpfCnpj) {
      if(cpfCnpj){
        const size = cpfCnpj.length;
        return size == 11 
          ? `CPF ${MaskCpfCnpjBlur(cpfCnpj, size)}`
          : `CNPJ ${MaskCpfCnpjBlur(cpfCnpj, size)}`;
      }
    },
    convertUtcDateToString(date, locale, isDateTime){
      const tmp = new Date(date);
      const newDate = new Date(date).addHours(3);
      return isDateTime ? tmp.toLocaleString(locale) : newDate.toLocaleDateString(locale);
    },

    confirmConsent(){
      if (!this.consentDetailsConfirm.dataAuthorize){
        return this.$refs.exceptionNotDataAuthorize.enable();
      }
      this.$router.push({
        name: this.redirectRoute,
        params: {
          requestFor: 'confirm',
          accountId: JSON.stringify(this.consentDetailsConfirm.accountId),
          creationDateTime: this.consentDetailsConfirm.creationDateTime,
          fase: 2
        }
      });
    },

    cancelConsent(){
      this.$router.push({
        name: this.redirectRoute,
        params: {
          requestFor: 'cancel',
          accountId: JSON.stringify(this.consentDetailsConfirm.accountId),
          creationDateTime: this.consentDetailsConfirm.creationDateTime,
          fase: 2
        }
      });
    },

    selectIfTheme () {
      const If = getTheme();

      switch (If) {
        case 'exb':
          return 'TecBan'
          break;
        case 'amz':
          return 'Banco da Amazônia'
          break;
        case 'bcd':
          return 'BrasilCard'
          break;
        case 'bs2':
          return 'BS2'
          break;
        case 'ccb':
          return 'China Construction Bank'
          break;
        case 'cds':
          return 'CredSIS'
          break;
        case 'csy':
          return 'CredSystem'
          break;
        case 'let':
          return 'Lets Bank'
          break;
        case 'bmx':
          return 'Master'
          break;
        case 'ori':
          return 'Ourinvest'
          break;
        case 'voi':
          return 'Voiter'
          break;
        case 'zem':
          return 'Zema'
          break;
      }
    }
  },
  created(){
    this.internalizationConfCons = getInternalization();
    this.consentDetails = getWithExpiry("completeData");
  },

  async mounted(){
    // VERIFICAR
    this.$root.$refs.mainNavbar.showClientNavbar();
    this.$root.$refs.mainNavbar.hideGoBackButton();
    this.tppName = this.consentDetails ? this.consentDetails.data.tpp.tppName : null;
    this.businessName = this.consentDetails.data.request.data.businessEntity;
    this.document = this.consentDetails && this.businessName ? this.businessName.document.identification : this.consentDetails.data.request.data.loggedUser.document.identification;
    this.typeDocument = this.consentDetails && this.businessName ? this.businessName.document.rel : this.consentDetails.data.request.data.loggedUser.document.rel;
    this.shareDetail = JSON.parse(getWithExpiry('dados'));
    this.expirationDate = this.convertUtcDateToString(this.consentDetails.data.consentBody.data.expirationDateTime, 'pt-BR', true);
    this.creationDate = this.convertUtcDateToString(this.consentDetails.data.consentBody.data.creationDateTime, 'pt-BR', true);
    this.ifName = this.selectIfTheme();
  }
}
</script>

<style scoped>
#d1{
  display: none;
  position: relative;
  z-index: 1000;
}
.bStyle{
  background-color: var(--confcons-page-background-color);
  height: auto;
  left: 0;
  padding: 20px;
}
.text{
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  line-height: 24px;
  text-align: left;
  margin-top: 8px;
}
.weight{
  font-weight: var(--confcons-box2-down-text-Weight);
}
.box2innertext{
  color: var(--confcons-box2-inner-text-color);
}
.box2innertitle{
  color: var(--confcons-box2-inner-title-color);
}
.title{
  font-style: normal;
  font-weight: var(--confcons-back-title1-font-weight);
  font-size: 24px;
  line-height: 32px;
  text-align: left;
  margin-top: 8px;
  color: var(--confcons-title-main-color);
}
.subtitle{
  margin-top: 8px;
  font-style: normal;
  font-weight: var(--confcons-box3-subtitle-text-weight);
  font-size: var(--confcons-box3-subtitle-text-size);
  line-height: 26px;
  text-align: left;
}
.boxGrey{
  background-color: var(--confcons-box1-background-color);
  box-shadow: var(--confcons-box1-shadow);
  margin-bottom: 25px;
  padding: 17px;
}
.textboxGrey{
  color: var(--confcons-box1-text-base-color);
  font-size: 18px;
}
.titleboxGrey{
  color: var(--confcons-box1-text-title-color);
}
#iframeMFA{
  border: none;
  border-radius: 15px;

  width: var(--mfa-width);
  height: var(--mfa-height);
}
.shareBtnBorder{
  border: 1px solid #808080 !important;
}

.box-grey-center {
  text-align-last: center;
}
</style>
