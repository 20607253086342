<template>

  <div class="painel" :style="GreyContainer">
    <slot></slot>
  </div>

</template>

<script>

export default {

  data() {
    return {
      GreyContainer: {}
    };
  },
  created() {
    this.GreyContainer = {
      'border-radius': '8px'
    };
  }
};
</script>

<style scoped>
</style>