<template>

  <Button :type="tipo" @click="disparaAcao()" :style="customButtons" class="buttonpattern">
    {{rotulo}}
  </Button>

</template>

<script>

export default {
  data(){
    return {
      customButtons:{
      }
    };
  },
  created() {
    this.customButtons={
      backgroundColor:'var(--button-cancelconsent-background-color)',
      color:'var(--button-cancelconsent-text-color)',
      border: 'var(--button-cancelconsent-border)',
      'box-shadow': 'var(--button-cancelconsent-shadow)',
      'font-style': 'normal',
      'font-weight': 'bold',
      'font-size': 'var(--button-cancelconsent-text-size)'
    };
  },
  name: 'Mbutton',
  props: {
    tipo: {
      type: String,
      required: true
    },
    rotulo: {
      type: String,
      required: true
    },
    confirmacao: Boolean,
  },
  methods: {

    disparaAcao() {

      if (this.confirmacao) {

        if (confirm('Confirma operacao?')) {
          this.$emit('botaoAtivado');
        }
        return;
      }
      this.$emit('botaoAtivado');
    }
  }
};
</script>

<style scoped>

.buttonpattern{
  border-radius: 8px;
  display: block;
  padding-top: 16px;
  padding-bottom: 16px;
  width: 100%;
}
.buttonpattern:hover {
  background: var(--button-cancelconsent-background-hover-color) !important;
}

.accountcancelbutton{
  background-color: var(--button-cancelaccount-background-color) !important;
  border: var(--button-cancelaccount-border) !important;
  color: var(--button-cancelaccount-text-color) !important;
  box-shadow: var(--button-cancelaccount-shadow) !important;
}
.accountcancelbutton:hover{
  background-color: var(--button-cancelaccount-background-hover-color) !important;
  border: var(--button-cancelaccount-border) !important;
  color: var(--button-cancelaccount-text-color) !important;
}

</style>