
import { defineComponent } from 'vue';
import { getLogoIniciadora, getTheme } from '@/util/util.js';

export default defineComponent({
  name: 'TecbanRedirectCardShadow',
  props: {
    imgName: String,
    viewName: String,
    cardLabel: String,
    pstyle: String
  },
  data() {
    return {
      urlIniciadora: '',
      theme: ''
    };
  },
  methods: {
    test(message: string) : void {
      console.log(message);
    },
    goToView(viewName: string, params?: Record<string, string | number | (string | number)[] | null | undefined>) : void {
      this.$router.push({ name: viewName, params});
    },
    themeLogo(){
      this.theme = getTheme();
    }
  },
  mounted() {
    this.urlIniciadora = getLogoIniciadora();
  }
});
