<template>
  <div class="mb-2 history-consent-box">
    <div v-bind:class="getCssClassBox(cardResumeHistoryConsentStatus)">
      <div v-bind:class="getCssClass(cardResumeHistoryConsentStatus)">
        <p class="card card-status card-status-title mb-2 ml-2">
          {{getStatus(cardResumeHistoryConsentStatus.toUpperCase())}}
        </p>
        <p class="card card-status card-status-description ml-2">
          <span>
            {{convertUtcToLocale(cardResumeHistoryUpdateDate)}}
          </span>
        </p>
      </div>
      <div class="row" v-bind:class="getCssClassDescriptionContainer(cardResumeHistoryConsentStatus)">
        <div class="pt-2">
          <div v-bind:class="getCssClassToggler(cardResumeHistoryConsentStatus)" ></div>
        </div>
        <div class="col-12">
          <p class="card card-info-title mb-1 ml-2">{{cardResumeHistoryTitle}}</p>
        </div>
        <div  class="col-12">
          <p>Nenhuma transação iniciada até o presente momento.</p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { defineComponent } from 'vue';
import {GetConsentCssClass, GetConsentStatus} from '@/util/util.js';
import moment from 'moment';


export default defineComponent({
  name: 'ConsentStatusCardResumeHistoryEmpty',
  data() {
    return{
      cardResumeHistoryBankName: '',
      cardResumeHistoryConsentStatus: '',
    };
  },
  props: {
    isEmptyHistory: Boolean,
    consentID: String,
    cardResumeHistoryTitle: String,
    cardResumeHistoryUpdateDate: String,
    isSweeping: Boolean,
  },
  mounted() {
    this.getDataResumeHistory();
  },
  methods: {
    getDataResumeHistory(){
      const getCardResumeHistoryBankName = JSON.stringify(sessionStorage.getItem('resumeHistoryBankName'));
      const getCardResumeHistoryConsentStatus = JSON.stringify(sessionStorage.getItem('resumeHistoryConsentStatus'));
      this.cardResumeHistoryConsentStatus = getCardResumeHistoryConsentStatus.substring(1, getCardResumeHistoryConsentStatus.length-1);
    },
     test(message){
      console.log(message);
    },
    getCssClassBox(status)  {
      return 'grid box box-' + status;
    },
    getCssColor(status)  {
      return 'statusColor-' + GetConsentCssClass(status);
    },
    getStatus(status) {
      return GetConsentStatus(status);
    },
    getCssClass(status)  {
      return 'col-12 card-header card-header-' + status;
    },
    getCssClassToggler(status)  {
      return 'm-auto toggler toggler-' + status;
    },
    getCssClassDescriptionContainer(status)  {
      return 'card-status-description-container card-status-description-container-' + status;
    },
    getCssDebtorData(haveDebtorData)  {
      return haveDebtorData ? 'display: block' : 'display: none';
    },
    convertUtcToLocale(date) {
      return moment.utc(date).local().format('DD/MM/YYYY HH:mm:ss').replace(' ', ' - ');
    },
    convertToDateDayMonth(date){
      return moment.utc(date).local().format('DD/MM').replace(' ', ' - ');
    },
    convertToDateYear(date){
      return moment.utc(date).local().format('YYYY').replace(' ', ' - ');
    },   
    convertAmount(value) {
      const formatter = new Intl.NumberFormat('pt-BR', {
        style: 'currency',
        currency: 'BRL',
        minimumFractionDigits: 2,
        maximumFractionDigits: 4,
      });

      return formatter.format(value);
    },
  }
});
</script>

<style scoped>

.box {
  background-color: #FFFFFF;
  box-shadow: var(--shadow-default);
  border-radius: 8px;
  margin: 1em
}
.box-waiting {
  background-color: var(--consultconsent-awaiting-background-color);
}
.box-consumed {
  background-color: var(--consultconsent-consumed-background-color);
}
.box-revoked {
  background-color: var(--consultconsent-revoked-background-color);
}
.box-authorised {
  background-color: var(--consultconsent-authorised-background-color);
}
.box-expired {
  background-color: var(--consultconsent-expired-background-color);
}
.box-rejected {
  background-color: var(--consultconsent-rejected-background-color);
}
.box-unavailable {
  background-color: var(--consultconsent-unavailable-background-color);
}
.statusColor-waiting, .statusColor-scheduled_payments {
  color: var(--consultconsent-awaiting-background-color);
}
.statusColor-consumed {
  color: var(--consultconsent-consumed-background-color);
}
.statusColor-revoked {
  color: var(--consultconsent-revoked-background-color);
}
.statusColor-authorised, .statusColor-liquidated_payments {
  color: var(--consultconsent-authorised-background-color);
}
.statusColor-expired {
  color: var(--consultconsent-expired-background-color);
}
.statusColor-rejected, .statusColor-canceled_payments {
  color: var(--consultconsent-rejected-background-color);
}
.statusColor-unavailable {
  color: var(--consultconsent-unavailable-background-color);
}

.card {
  font-style: normal;
  display: flex;
  align-items: center;
}
.card-header {
  min-height: 74px;
  min-width: 312px;
  border-radius: 8px 8px 0px 0px;
  padding: 0.5em;
}

.toggler {
  border-radius: 8px;
  min-height:6px;
  width:37px;
}

.card-header-waiting {
  background: var(--consultconsent-awaiting-background-color);
}
.toggler-waiting {
  background: var(--consultconsent-awaiting-background-color);
}

.card-header-consumed {
  background: var(--consultconsent-consumed-background-color);
}
.toggler-consumed {
  background: var(--consultconsent-consumed-background-color);
}

.card-header-revoked {
  background: var(--consultconsent-revoked-background-color);
}
.toggler-revoked {
  background: var(--consultconsent-revoked-background-color);
}

.card-header-authorised {
  background: var(--consultconsent-authorised-background-color);
}
.toggler-authorised {
  background: var(--consultconsent-authorised-background-color);
}

.card-header-expired {
  background: var(--consultconsent-expired-background-color);
}
.toggler-expired {
  background: var(--consultconsent-expired-background-color);
}

.card-header-rejected {
  background: var(--consultconsent-rejected-background-color);
}
.toggler-rejected {
  background: var(--consultconsent-rejected-background-color);
}

.card-header-unavailable {
  background: var(--consultconsent-unavailable-background-color);
}
.toggler-unavailable {
  background: var(--consultconsent-unavailable-background-color);
}

.card-header-waiting .card-status {
  color: var(--consultconsent-awaiting-text-color);
}
.card-header-rejected .card-status {
  color: var(--consultconsent-rejected-text-color);
}
.card-header-unavailable .card-status {
  color: var(--consultconsent-unavailable-text-color);
}
.card-header-consumed .card-status {
  color: var(--consultconsent-consumed-text-color);
}
.card-header-revoked .card-status {
  color: var(--consultconsent-revoked-text-color);
}
.card-header-authorised .card-status {
  color: var(--consultconsent-authorised-text-color);
}
.card-header-expired .card-status {
  color: var(--consultconsent-expired-text-color);
}
.card-status {
  font-weight: bold;
}
.card-status-title {
  font-size: 16px;
  line-height: 22px;
}

.card-status-description-container {
  border-radius: 8px;
  background-color: var(--page-background-color);
  width: 100%;
}
.card-status-description-container-waiting {
  border: 1px solid var(--consultconsent-awaiting-background-color);
}
.card-status-description-container-consumed {
  border: 1px solid var(--consultconsent-consumed-background-color);
}
.card-status-description-container-revoked {
  border: 1px solid var(--consultconsent-revoked-background-color);
}
.card-status-description-container-authorised {
  border: 1px solid var(--consultconsent-authorised-background-color);
}
.card-status-description-container-expired {
  border: 1px solid var(--consultconsent-expired-background-color);
}
.card-status-description-container-rejected {
  border: 1px solid var(--consultconsent-rejected-background-color);
}

.card-status-description-container-unavailable {
  border: 1px solid var(--consultconsent-unavailable-background-color);
}

.card-status-description {
  font-size: 12px;
  line-height: 12px;
}
.card-inner-text {
  color: var(--consultconsent-box-down-text-color);
  font-weight: normal;
  font-size: 16px;
  line-height: 24px;
  margin:0;
  word-break: break-all;
}
.card-info-title {
  color: var(--consultconsent-box-down-title-color);
  font-weight: var(--consultconsent-box-down-title-weight);
  font-size: 20px;
  line-height: 24px;
}
.navYear{
  display: flex;
  justify-content: space-between;
  margin: 20px 0 0;
}
.navYear p{
  margin: 0;
  padding: 0;
  font-size: 1.2em;
  font-weight: 700;
}
.navYear--iconArrow_left, .navYear--iconArrow_right{
  display: block;
  cursor: pointer;
  border-left: 12px solid #3C3C3C;
  border-top: 7px solid transparent;
  border-bottom: 7px solid transparent;
  width: 12px;
  height: 7px;
  margin: 4px 10px 0px;
}
.navYear--iconArrow_left{
  transform: rotate(180deg);
}
.navYear--iconArrow_left.disabled, .navYear--iconArrow_right.disabled{
  cursor: auto;
  border-left: 12px solid #ECEBED;
  pointer-events: none;
}
.navYear__listPayments{
  display: block;
  height: 165px;
  overflow: hidden;
}
.navYear__listPayments.showMoreInfo{
  height: auto;
  overflow: auto;
}
.show-more {
  padding-top: 12px;
  padding-bottom: 12px;
  border-radius: 0px 0px 8px 8px;
  text-align-last: center;
  background-color: #FFF;
}
.cardShowMore{
  cursor: pointer;
  color: var(--expansive-card-label-show-more-color);
  font-size: 12px;
  font-weight: bold;
  text-decoration-line: underline;
}
.listPaymentDetails{
  display: flex;
  list-style: none;
  justify-content: space-between;
  margin: 10px;
  padding: 0;
  color: #3C3C3C;
}
.listPaymentDetails li{
  margin: auto 0;
}
@media (max-width: 768px){
  .p-timeline.p-timeline-vertical .p-timeline-event-content{
    padding: 0 0.5rem 1rem 1rem;
  }
  .card {
    align-items: flex-start;
    flex-direction: column;
  }
}
</style>