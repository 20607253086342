
import { defineComponent } from 'vue';
import { getTheme } from '@/util/util.js';

export default defineComponent({
  name: 'ImageFlexContainer',
  props: {
    align: String
  },
  data(){
    return{
      themeLogo: ''
    };
  },
  created(){
    this.themeLogo = getTheme();
  }
});





