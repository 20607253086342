
<template>
  <div class="painel" :style="pstyle">
    
    <h2 class="painel-titulo" :style="ptstyle">
      <div v-if="!sweeping" :style="ftype">
        {{ titulo }}
      </div>
      <div v-else :style="ftype">
        Regras de autorização
      </div>
      <div v-if="!sweeping" :style="ftype2">
        {{valor}}
      </div> 
    </h2>

    <div class="painel-conteudo" :style="pcstyle">
      <slot></slot>
    </div>

  </div>
</template>

<script>
export default {

  props: ['titulo','valor', 'pstatus', 'pstyle','ptstyle','pcstyle','pcclass','ftype','ftype2', 'sweeping'],

  data() {
    return {};
  }
};

</script>

<style scoped>

.painel {
  border-radius: 15px;
  box-shadow: var(--shadow-default);
}
.painel .painel-titulo {
  margin-left: 17px;
  margin-right: 15px;
  text-align: left;
  border-radius: 8px;
}
.painel-conteudo{
  box-sizing: border-box;
  border-radius: 8px;
}
.painel-authorised.painel{
  background-color: var(--consultconsent-authorised-background-color);
}
.painel-awaiting.painel{
  background-color: var(--consultconsent-awaiting-background-color);
}
.painel-rejected.painel{
  background-color: var(--consultconsent-rejected-background-color);
}
.painel-revoked.painel{
  background-color: var(--consultconsent-revoked-background-color);
}
.painel-temporarily.painel {
  background-color: #999999;
}
.painel-null.painel{
  background-color: var(--confcons-box2-up-background-color);
}
.painel-authorised .painel-conteudo {
  border: 1px solid var(--consultconsent-authorised-background-color);
}
.painel-awaiting .painel-conteudo {
  border: 1px solid var(--consultconsent-awaiting-background-color);
}
.painel-rejected .painel-conteudo {
  border: 1px solid var(--consultconsent-rejected-background-color);
}
.painel-revoked .painel-conteudo {
  border: 1px solid var(--consultconsent-revoked-background-color);
}
.painel-null .painel-conteudo {
  border: 1px solid var(--confcons-box2-up-background-color);
}


</style>