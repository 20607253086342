import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-760a8c2e"),n=n(),_popScopeId(),n)
const _hoisted_1 = { key: 0 }
const _hoisted_2 = {
  key: 0,
  id: "nav",
  class: "flex p-3 card"
}
const _hoisted_3 = {
  key: 0,
  class: "mr-2 if-container-padding"
}
const _hoisted_4 = { class: "ml-auto if-container-padding" }
const _hoisted_5 = {
  key: 1,
  id: "navManager",
  class: "flex pt-3 pb-3 card top-bar"
}
const _hoisted_6 = { class: "mr-2 if-container-padding" }
const _hoisted_7 = {
  class: "",
  style: {"align-self":"center"}
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_GoBackButton = _resolveComponent("GoBackButton")!
  const _component_IfInfo = _resolveComponent("IfInfo")!
  const _component_ImageFlexContainer = _resolveComponent("ImageFlexContainer")!

  return (_ctx.$route.name !== 'Redirect IF' && _ctx.showManagerNav)
    ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
        (!_ctx.managerNav)
          ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
              (_ctx.showGoBack)
                ? (_openBlock(), _createElementBlock("div", _hoisted_3, [
                    _createVNode(_component_GoBackButton, { label: _ctx.bntGoBackLabel }, null, 8, ["label"])
                  ]))
                : _createCommentVNode("", true),
              _createElementVNode("div", _hoisted_4, [
                _createVNode(_component_IfInfo, {
                  ifName: _ctx.ifName,
                  width: 37,
                  height: 34
                }, null, 8, ["ifName"])
              ])
            ]))
          : (_openBlock(), _createElementBlock("div", _hoisted_5, [
              _createElementVNode("div", _hoisted_6, [
                _createVNode(_component_ImageFlexContainer, { align: "tecban-center" })
              ]),
              _createElementVNode("div", _hoisted_7, [
                _createVNode(_component_ImageFlexContainer, { align: "open-banking-center" })
              ])
            ]))
      ]))
    : _createCommentVNode("", true)
}