<template>
    <div>
        <Paginator 
            :first="first" 
            :rows="10"
            :totalRecords="total"
            @page="makeAction($event)"
            currentPageReportTemplate="({currentPage} de {totalPages})"
            template="FirstPageLink PrevPageLink CurrentPageReport JumpToPageDropdown JumpToPageInput NextPageLink LastPageLink">
        ></Paginator>
    </div>

</template>

<script>
import { defineComponent } from 'vue';


export default defineComponent({
    data() {
        return {
            dropDownPageSelected: '',
            allPages: [],
            first: 0
        };
    },
    name: 'MyConsentsPaginator',
    props: {
        currentPage: Number,
        totalPages: Number,
        total: Number,
        changePageAction: Function
    },
    methods: {
        makeAction(event){
          this.$emit('changePageAction', event.page+1);
        },
        changePage(page) {
            this.$emit('changePageAction', page);
        },
        changePageSelected(){
            this.changePage(this.dropDownPageSelected);
        }
    },
    created() {
        this.first = (this.currentPage-1)*10;
    }
});
</script>


<style scoped>
.dropdownPage {
    bottom: 7px;
    width: 53px;
    height: 38px;
}
.paginatorContent {
  font-size: 14px;
  display: inline-flex;
  line-height: 24px;
  vertical-align: middle;
  margin: 0px;
  padding: 0px;
}

.content-current-paginator {
  font-weight: 700;
  margin-right: 8px;
  color: var(--primary-color)
}

.content-max-page {
  margin-left: 8px;
}

.icons-left-arrows {
  left: 4%;
  position: absolute;
}

.icons-right-arrows {
  right: 4%;
  position: absolute;
}

.cursor-pointer {
  cursor: pointer;
}

</style>
