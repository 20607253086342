<template>

  <BModal id="calendar" @click="disable" ref="bmodalc" class="w-sm-75" customClass="filterModalBox" customClassDisable="filterModalDisable">

    <div class="fWrapper">

      
      <div class="divHeader">
        <div class="divHeaderTitle">
          <span class="header">{{internalizationFilterCalendar.filterClientCalendarTitle}}</span>
        </div>

        <div class="divHeaderError">
          <span v-if="!isValidIntervalDates && tryedToFilter" class="headerError">
            {{internalizationFilterCalendar.filterClientCalendarErrorLabel}}
          </span>
        </div>

        <div>
          <span class="closeBtn" @click="disable()"><i class="pi pi-times"></i></span>
        </div>
      </div>
      <div class="input-date-filters">
        <div class="field-calendar"><label class="mr-3" for="date">De</label>
          <InputMask mask="99/99/9999" v-model="filterDateModelStart" placeholder="dd/mm/aaaa" slotChar="dd/mm/aaaa" @update:model-value="onChangeFilterDate" />
        </div>

        <div class="field-calendar"> <label class="mr-3" for="date">Até</label>
          <InputMask mask="99/99/9999" v-model="filterDateModelEnd" placeholder="dd/mm/aaaa" slotChar="dd/mm/aaaa" @update:model-value="onChangeFilterDate" @blur="onBlurFilterDate"/>
        </div>
      </div>
      <Calendar
        ref="fCalendar"
        class="filterPCalendar"
        v-model="dateModel"
        :inline="true"
        :showWeek="false"
        dateFormat="dd/mm/yy"
        selectionMode="range"
      />

      <div>
        <Bbutton
          :rotulo="internalizationFilterCalendar.filterClientCalendarClearButtonLabel"
          tipo="submit"
          class="modalCalendarClear"
          :confirmacao="false"
          @botaoAtivado="clearFilter"
        />

        <Mbutton
          :rotulo="internalizationFilterCalendar.filterClientCalendarFilterButtonLabel"
          :disabled="!isValidIntervalDates && tryedToFilter"
          tipo="button"
          class="modalCalendarFilter"
          :confirmacao="false"
          @click="makeAction()"
        />
      </div>

    </div>
      
  </BModal>

</template>

<script>

import { getInternalization } from '@/util/util.js';
import moment from 'moment';

export default {
  data() {
    return {
      dateModel: null,
      strDateModelStart: null,
      strDateModelEnd: null,
      filterDateModelStart: null,
      filterDateModelEnd: null,
      dateModelStart: null,
      dateModelEnd: null,

      isValidDateDigits: true,
      isValidIntervalDates: false,
      tryedToFilter: false,
      modalOpened: false
    };
  },
  methods: {
    clickOutside(event) {

      if (this.modalOpened) {
        if (event.target.className ==='disableScreen filterModalDisable') {
          this.disable();
        }
      }

      if (this.$refs.bmodalc.showModal) {
        this.modalOpened = true;
      }
      },

    enable(){
      this.$refs.bmodalc.enable();
    },

    clearFilter() {
      this.$refs.fCalendar.updateModel(null);
      this.filterDateModelStart = '';
      this.filterDateModelEnd = '';
    },

    disable(){
      this.$refs.bmodalc.disable();
      this.modalOpened = false;
    },

    makeAction(){
      this.tryedToFilter = true;
      this.updateIsValidIntervalDates();

      if (this.isValidIntervalDates){
        this.$emit('action');
        this.disable();
      }

    },
    onBlurFilterDate(event) {
      if (
        !moment(this.filterDateModelStart, 'DD/MM/YYYY', true).isValid() ||
        !moment(this.filterDateModelEnd, 'DD/MM/YYYY', true).isValid() ||
        !moment(this.filterDateModelStart, 'DD/MM/YYYY', true).isSameOrBefore(moment(this.filterDateModelEnd, 'DD/MM/YYYY', true).toDate())
        ){
          this.clearFilter();
        }
    },
    onChangeFilterDate(event){
      if (moment(event, 'DD/MM/YYYY', true).isValid()) {
        if(event == this.filterDateModelStart && moment(this.filterDateModelStart, 'DD/MM/YYYY', true).isBefore(new Date())) {
          this.strDateModelStart = event;
          this.$refs.fCalendar.updateModel([moment(event, 'DD/MM/YYYY', true).toDate()]);
        } else if (
          event == this.filterDateModelEnd &&
          moment(this.filterDateModelStart, 'DD/MM/YYYY', true).isValid() &&
          moment(this.filterDateModelStart, 'DD/MM/YYYY', true).isBefore(moment(this.filterDateModelEnd, 'DD/MM/YYYY', true).toDate())
        ){
          this.strDateModelEnd = event;
          this.$refs.fCalendar.updateModel([moment(this.filterDateModelStart, 'DD/MM/YYYY', true).toDate(), moment(event, 'DD/MM/YYYY', true).toDate()]);
        }
      }
    },
    isValidDateStr(date){
      if (date == null || date == '' || date.includes('d') || date.includes('m') || date.includes('a'))
        return false;
      return true;
    },

    verifyValidDateDigits(){
      if(this.isValidDateStr(this.strDateModelStart) && this.isValidDateStr(this.strDateModelEnd)){
        this.isValidDateDigits = moment(this.strDateModelStart, 'DD/MM/YYYY', true).isValid() && moment(this.strDateModelEnd, 'DD/MM/YYYY', true).isValid();
      }
      else if(this.isValidDateStr(this.strDateModelStart)){
        this.isValidDateDigits = moment(this.strDateModelStart, 'DD/MM/YYYY', true).isValid();
      }
      else if(this.isValidDateStr(this.strDateModelEnd)){
        this.isValidDateDigits = moment(this.strDateModelEnd, 'DD/MM/YYYY', true).isValid();
      }
      else{
        this.isValidDateDigits = true;
      }
    },

    updateIsValidIntervalDates(){
      this.verifyValidDateDigits();
      this.isValidIntervalDates = this.isValidDateDigits && this.dateModelStart != null && this.dateModelStart != '' && 
        this.dateModelEnd != null && this.dateModelEnd != '';
    },

    getFormatedDateddmmyyyy(date){
      return ('0' + date.getDate()).slice(-2) + '/' + ('0' + (date.getMonth()+1)).slice(-2) + '/' + ('000' + date.getFullYear()).slice(-4);
    },

    onEsc(e) {
      if (!e) e = window.event;
      const keyCode = e.keyCode || e.which;
      if (keyCode == '27') {
        this.disable();
      }
    }
  },
  watch: {
    dateModel: function (val) {

      this.tryedToFilter = false;
      if( Array.isArray(val) ){
        if (val[1] == null || val[1] == ''){
          const dateTmp = this.getFormatedDateddmmyyyy(val[0]);
          this.strDateModelEnd = 'dd/mm/aaaa';
          this.strDateModelStart = dateTmp;
          this.filterDateModelStart = dateTmp;

          if (this.isValidDateStr(this.strDateModelStart)){
            const tmp = this.strDateModelStart.split('/');
            this.dateModelStart = new Date(`${tmp[1]}/${tmp[0]}/${tmp[2]}`);
          }
        }
        else{
          const dateTmp = this.getFormatedDateddmmyyyy(val[1]);
          this.strDateModelEnd = dateTmp;
          this.filterDateModelEnd = dateTmp;

          if (this.isValidDateStr(this.strDateModelEnd)){
            const tmp = this.strDateModelEnd.split('/');
            this.dateModelEnd = new Date(`${tmp[1]}/${tmp[0]}/${tmp[2]}`);
          }
        }
      }

        this.updateIsValidIntervalDates();
      
    }
  },
  created(){
    document.addEventListener('keyup', this.onEsc);
    this.internalizationFilterCalendar = getInternalization();

    this.$primevue.config.locale.dayNamesMin = [ 'Dom', 'Seg', 'Ter', 'Qua', 'Qui', 'Sex', 'Sáb' ];
    this.$primevue.config.locale.monthNames = [ 'Janeiro', 'Fevereiro', 'Março', 'Abril', 'Maio', 'Junho',
      'Julho', 'Agosto', 'Setembro', 'Outubro', 'Novembro', 'Dezembro' ];
  },

  beforeUnmount() {
    document.removeEventListener('keyup', this.onEsc);
    document.removeEventListener('click', this.clickOutside);

  },

  mounted(){
    document.addEventListener('click', this.clickOutside);
    document.getElementById(document.querySelector('div[id^=\'pv_id_\'][id$=\'_panel\']').id)?.removeAttribute('style'); //ajuste para remover style=width:0
  }
};
</script>

<style scoped>
.divCloseBtn{
  text-align: left;
  margin: 0 10px 10px 0;
}
.divHeader{
  display: flex;
  justify-content: space-between;
  margin: 0px auto 3px auto;
  padding: 0 12px;
}
.divHeaderTitle, .divHeaderError{
  display: inline-block;
}
.divHeaderTitle{
  float: left;
}
.divHeaderError{
  float: right;
}
.closeBtn, .header{
  font-style: normal;
  font-size: 24px;
  line-height: 32px;
}
.header{
  color: var(--filterclient-calendar-maintitle-color);
}
.headerError{
  font-size: 12px;
  font-weight: normal;
  color: var(--filterclient-calendar-texterror-color);
}
.closeBtn{
  display: flex;
  font-weight: normal;
  color: var(--filterclient-calendar-closeBtn-color);
  cursor: pointer;
}
.pi-times:hover, .pi-times:focus{
  font-weight: bold;
}
.input-date-filters {
  margin: 0 auto;
  display: flex;
  justify-content: space-evenly;
}

.p-inputmask {
  width: 122px;
}

.field-calendar {
  display: flex;
  align-items: center;  
  justify-content: center;
}
</style>

<style>
/* not scoped for calendar */

.filterPCalendar .datepicker{
  border: none;
}
.filterPCalendar .datepicker, .filterPCalendar .datepicker-header{
  background-color: var(--page-background-color) !important;
}
.filterPCalendar .datepicker-month, .filterPCalendar .datepicker-year{
  font-weight: bold;
  color: var(--filterclient-calendar-monthyear-color);
  font-size: var(--filterclient-calendar-monthyear-font-size);
}
.filterPCalendar .datepicker-prev-icon, .filterPCalendar .datepicker-next-icon{
  color: var(--filterclient-calendar-arrowmonthyear-color);
}

.filterPCalendar.disableNextPreviousMonth .datepicker-prev, .filterPCalendar.disableNextPreviousMonth .datepicker-next{
  display: none;
}

.filterPCalendar .datepicker-calendar thead span{
  color: var(--filterclient-calendar-daysname-color);
}

.filterPCalendar .datepicker-calendar tbody span, .filterPCalendar .datepicker-calendar td.datepicker-today > span{
  color: var(--filterclient-calendar-daysnum-color) !important;
}

.p-datepicker table td.p-datepicker-today > span{
  background-color: var(--filterclient-calendar-today-background-color) !important;
  border: var(--filterclient-calendar-today-border) !important;
}

.p-datepicker table td > span.p-highlight{
  background-color: var(--filterclient-calendar-item-selectedhover-background-color) !important;
  color: var(--filterclient-calendar-item-selectedhover-color) !important;
}

.p-datepicker table td > span:focus{
  outline: 0 none !important;
  outline-offset: 0 !important;
  box-shadow: 0 0 0 0.2rem var(--filterclient-calendar-item-focus-boxshadow-color) !important;
  background-color: var(--filterclient-calendar-item-focus-bg-color) !important;
}


.p-datepicker 
.p-datepicker-header 
.p-datepicker-title 
.p-datepicker-year, 
.p-datepicker 
.p-datepicker-header 
.p-datepicker-title 
.p-datepicker-month {
  font-size: 18px;
  font-size: bold;
  color: var(--month-and-year-color)!important;
}

.p-datepicker 
.p-datepicker-header 
.p-datepicker-prev, 
.p-datepicker 
.p-datepicker-header 
.p-datepicker-next {
  color: var(--arrow-next-previous)!important;
}

.labe-input-calendar {
  position: absolute; 
  margin-top: -20px;
}

.p-datepicker {
  padding: 0 !important;
}
.filterPCalendar .p-datepicker {
  padding: 0;
}

.p-calendar, .p-datepicker, .p-datepicker-header {
  background: transparent !important;
}
</style>